import { createContext, useEffect, useReducer } from 'react';
import axios from '../utils/axios';
import { CancelToken } from '../utils/axios';
import useAuth from '../hooks/useAuth';
import { format, parseISO } from 'date-fns'
import _ from 'lodash';
import { defaultSettingClientListHeader, defaultSettingSubordinateListHeader, defaultSettingProductListHeader } from '../config';
import { DROPDOWN_VIEW, DROPDOWN_DOC_VIEW } from '../utils';

const initialState = {
  client: {},
  clientList: [],
  clientListFilterParams: null,
  settingClientListHeader: [],
  selectedClientListHeader: [],
  subordinateListFilterParams: null,
  settingSubordinateListHeader: [],
  selectedSubordinateListHeader: [],
  pendAppListFilterParams: null,
  applicableRole: [],
  categories: [],
  productListFilterParams: null,
  settingProductListHeader: [],
  selectedProductListHeader: [],
};

const handlers = {
  SET_CLIENT_LIST_HEADER: (state, action) => {
    const { settingClientListHeader, selectedClientListHeader } = action.payload;
    return {
      ...state,
      settingClientListHeader,
      selectedClientListHeader,
    };
  },
  SET_SUBORDINATE_LIST_HEADER: (state, action) => {
    const { settingSubordinateListHeader, selectedSubordinateListHeader } = action.payload;
    return {
      ...state,
      settingSubordinateListHeader,
      selectedSubordinateListHeader,
    };
  },
  SET_CLIENT_PAGE_AND_FILTER_TAG: (state, action) => {
    return {
      ...state,
      clientListFilterParams: action.payload,
    };
  },
  SET_APPLICABLE_ROLE: (state, action) => {
    return {
      ...state,
      applicableRole: action.payload,
    };
  },
  SET_SUBORDINATE_PAGE_AND_FILTER_TAG: (state, action) => {
    return {
      ...state,
      subordinateListFilterParams: action.payload,
    };
  },
  SET_CATEGORIES: (state, action) => {
    return {
      ...state,
      categories: action.payload,
    };
  },
  SET_PRODUCT_LIST_HEADER: (state, action) => {
    const { settingProductListHeader, selectedProductListHeader } = action.payload;
    return {
      ...state,
      settingProductListHeader,
      selectedProductListHeader,
    };
  },
  SET_PRODUCT_PAGE_AND_FILTER_TAG: (state, action) => {
    return {
      ...state,
      productListFilterParams: action.payload,
    };
  },
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AppContext = createContext({
  state: {
    ...initialState
  },
  actions: {
    getClient: () => Promise.resolve(),
    getClientList: () => Promise.resolve(),
    saveNewClient: () => Promise.resolve(),
    saveEditClient: () => Promise.resolve(),
    deleteClient: () => Promise.resolve(),
    setClientListHeader: () => {},
    setClientPageAndFilterTag: () => {},
    getPendingApplicationList: () => Promise.resolve(),
    getPendingApplicationAccount: () => Promise.resolve(),
    getApplicableRole: () => Promise.resolve(),
    getAssignableStaffs: () => Promise.resolve(),
    approveApplication: () => Promise.resolve(),
    deleteApplication: () => Promise.resolve(),
    getSubordinateList: () => Promise.resolve(),
    setSubordinateListHeader: () => {},
    setSubordinatePageAndFilterTag: () => {},
  }
})

let cancel;

function AppProvider({ children }) {
  const { user } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = () => {
      const tempSettingClientListHeader = window.localStorage.getItem('settingClientListHeader');
      let settingClientListHeader = null;
      let selectedClientListHeader = null;

      if (tempSettingClientListHeader) {
        settingClientListHeader = JSON.parse(tempSettingClientListHeader);
      } else {
        settingClientListHeader = [...defaultSettingClientListHeader];
      }

      selectedClientListHeader = _.filter(settingClientListHeader, { 'status': true });

      dispatch({
        type: 'SET_CLIENT_LIST_HEADER',
        payload: {
          settingClientListHeader,
          selectedClientListHeader
        }
      });

      const tempSettingSubordinateListHeader = window.localStorage.getItem('settingSubordinateListHeader');
      let settingSubordinateListHeader = null;
      let selectedSubordinateListHeader = null;

      if (tempSettingSubordinateListHeader) {
        settingSubordinateListHeader = JSON.parse(tempSettingSubordinateListHeader);
      } else {
        settingSubordinateListHeader = [...defaultSettingSubordinateListHeader];
      }

      selectedSubordinateListHeader = _.filter(settingSubordinateListHeader, { 'status': true });

      dispatch({
        type: 'SET_SUBORDINATE_LIST_HEADER',
        payload: {
          settingSubordinateListHeader,
          selectedSubordinateListHeader
        }
      });


      const tempSettingProductListHeader = window.localStorage.getItem('settingProductListHeader');
      let settingProductListHeader = null;
      let selectedProductListHeader = null;

      if (tempSettingProductListHeader) {
        settingProductListHeader = JSON.parse(tempSettingProductListHeader);
      } else {
        settingProductListHeader = [...defaultSettingProductListHeader];
      }

      selectedProductListHeader = _.filter(settingProductListHeader, { 'status': true });

      dispatch({
        type: 'SET_PRODUCT_LIST_HEADER',
        payload: {
          settingProductListHeader,
          selectedProductListHeader
        }
      });
    };

    initialize();
  }, []);

  const setClientListHeader = (newSettingClientListHeader) => {
    const settingClientListHeader = _.cloneDeep(newSettingClientListHeader);
    const selectedClientListHeader = _.filter(settingClientListHeader, { 'status': true });
    window.localStorage.setItem('settingClientListHeader', JSON.stringify(settingClientListHeader));
    dispatch({
      type: 'SET_CLIENT_LIST_HEADER',
      payload: {
        settingClientListHeader,
        selectedClientListHeader
      }
    });
  }

  const setSubordinateListHeader = (newSettingSubordinateListHeader) => {
    const settingSubordinateListHeader = _.cloneDeep(newSettingSubordinateListHeader);
    const selectedSubordinateListHeader = _.filter(settingSubordinateListHeader, { 'status': true });
    window.localStorage.setItem('settingSubordinateListHeader', JSON.stringify(settingSubordinateListHeader));
    dispatch({
      type: 'SET_SUBORDINATE_LIST_HEADER',
      payload: {
        settingSubordinateListHeader,
        selectedSubordinateListHeader
      }
    });
  }

  const setProductListHeader = (newSettingProductListHeader) => {
    const settingProductListHeader = _.cloneDeep(newSettingProductListHeader);
    const selectedProductListHeader = _.filter(settingProductListHeader, { 'status': true });
    window.localStorage.setItem('settingProductListHeader', JSON.stringify(settingProductListHeader));
    dispatch({
      type: 'SET_PRODUCT_LIST_HEADER',
      payload: {
        settingProductListHeader,
        selectedProductListHeader
      }
    });
  }
  const getClientList = async (page, filterTag) => {
    let tempParams = '';
    filterTag.forEach(tag => {
      tempParams += `&${tag.key}=${tag.value}`
    });
    const limit = 10;
    const params = `?_limit=${limit}&page=${page}&order=desc&role_ids[]=6${tempParams}`;
    const res = await axios.get(`/users${params}`);
    return res;
  }

  const getClient = async (clientId) => {
    const res = await axios.get(`/users/${clientId}`);
    const resAttachments = await axios.get(`/users/${clientId}/others-attachments?exclude_encoded_raw_content=1`);
    const attachemnts = resAttachments.data.data.map(attachment => {
      return {
        id: attachment.id,
        mimeType: attachment.mime_type,
        name: attachment.title,
        size: attachment.content,
        status: 'saved',
      };
    });

    const clientObject = res.data.data;
    const currentClient = {
      id: clientObject.id,
      status_category_id: clientObject.status.id.toString(),
      name: clientObject.name,
      email: clientObject.email || '',
      ic_no: clientObject.ic_no || '',
      dob: clientObject.dob,
      mobile_phone: clientObject.mobile_phone,
      home_phone: clientObject.home_phone || '',
      gender: clientObject.gender,
      address: clientObject.featured_billing_location?.address,
      state: clientObject.featured_billing_location?.locality.state.id.toString(),
      locality_id: clientObject.featured_billing_location?.locality.id.toString(),
      postcode: clientObject.featured_billing_location?.postcode,
      smoker_category_id: clientObject.smoker?.id.toString(),
      number_of_cases: clientObject.number_of_cases?.toString(),
      occupation_category_id: clientObject.occupation?.id.toString(),
      occupation_category_remark: clientObject.occupation?.category_remark || '',
      remark: clientObject.remark || [],
      attachments: attachemnts,
      servicing_agent: clientObject.servicing_agent ? {
        id: clientObject.servicing_agent.id,
        name: clientObject.servicing_agent.name,
        username: clientObject.servicing_agent.username,
        role: clientObject.servicing_agent.role.title,
      } : null
    };

    return currentClient;
  }

  const saveNewClient = async (data) => {
    const res = await axios.post('/users', {
      role_id: 6,
      status_category_id: data.status_category_id,
      name: data.name,
      email: data.email,
      ic_no: data.ic_no,
      dob: data.dob,
      mobile_phone: data.mobile_phone,
      home_phone: data.home_phone,
      gender: data.gender,
      number_of_cases: data.number_of_cases,
      remark: JSON.stringify(data.remark.map(({ status, ...other }) => other)),
    });

    if (res.status === 201) {
      const clientId = res.data.data.id;

      try {
        const agentId = data.servicing_agent ? data.servicing_agent.id : user.id;
        await axios.post(`/users/${clientId}/servicer-parent`, { parent_user_id: agentId });
      } catch (error) {
      }

      try {
        await axios.post(`/users/${clientId}/billing-locations`, { address: data.address, postcode: data.postcode, locality_id: data.locality_id, is_featured: 1 });
      } catch (error) {
      }

      try {
        await axios.post(`/users/${clientId}/smoker-category`, { category_id: data.smoker_category_id });
      } catch (error) {
      }

      try {
        await axios.post(`/users/${clientId}/occupation-category`, { category_id: data.occupation_category_id, category_remark: data.occupation_category_remark });
      } catch (error) {
      }

      if (data.attachments && data.attachments.length > 0) {
        const attachmentsToSave = [];

        data.attachments.forEach(file => {
          delete file.preview;
          const formData = new FormData();
          formData.append("file", file);
          formData.append("title", file?.name);
          formData.append("content", file?.size);
          attachmentsToSave.push(axios.post(`users/${clientId}/others-attachments`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }));
        });

        try {
          await Promise.all(attachmentsToSave);
        } catch (error) {
        }
      }
    }
    return res;
  }

  const saveEditClient = async (data, currentClient) => {
    const clientId = currentClient.id;
    const newInfo = {
      status_category_id: data.status_category_id,
      name: data.name,
      email: data.email,
      ic_no: data.ic_no,
      dob: _.isDate(data.dob) ? format(data.dob, 'yyyy-MM-dd 00:00:00') : data.dob,
      mobile_phone: data.mobile_phone,
      home_phone: data.home_phone,
      gender: data.gender,
      number_of_cases: data.number_of_cases,
      remark: JSON.stringify(data.remark),
    }

    const currentInfo = {
      status_category_id: currentClient.status_category_id,
      name: currentClient.name,
      email: currentClient.email,
      ic_no: currentClient.ic_no,
      dob: currentClient.dob,
      mobile_phone: currentClient.mobile_phone,
      home_phone: currentClient.home_phone,
      gender: currentClient.gender,
      number_of_cases: currentClient.number_of_cases,
      remark: JSON.stringify(currentClient.remark),
    }

    const newAddress = {
      address: data.address,
      postcode: data.postcode,
      locality_id: data.locality_id,
    }

    const currentAddress = {
      address: currentClient.address,
      postcode: currentClient.postcode,
      locality_id: currentClient.locality_id,
    }

    const newOccupation = {
      category_id: data.occupation_category_id,
      category_remark: data.occupation_category_remark
    }

    const currentOccupation = {
      category_id: currentClient.occupation_category_id,
      category_remark: currentClient.occupation_category_remark
    }

    const attSaved = _.filter(data.attachments, { 'status': 'saved' });

    const attToSave = _.filter(data.attachments, { 'status': 'new' });

    const attToDelete = _.filter(data.attachments, { 'status': 'toRemove' });

    if (!_.isEqual(newInfo, currentInfo)) {
      await axios.put(`/users/${clientId}`, newInfo);
    }

    if (!_.isEqual(newAddress, currentAddress)) {
      await axios.put(`/users/${clientId}/featured-billing-location`, newAddress);
    }

    if (data.smoker_category_id !== currentClient.smoker_category_id) {
      await axios.put(`/users/${clientId}/smoker-category`, { category_id: data.smoker_category_id });
    }

    if (!_.isEqual(newOccupation, currentOccupation)) {
      await axios.put(`/users/${clientId}/occupation-category`, newOccupation);
    }

    if (data.servicing_agent?.id !== currentClient.servicing_agent?.id) {
      if (data.servicing_agent !== null) {
        await axios.put(`/users/${clientId}/servicer-parent`, { parent_user_id: data.servicing_agent.id });
      } else {
        await axios.delete(`/users/${clientId}/servicer-parent`);
      }
    }

    if (attToSave.length > 0) {
      const attachmentsToSave = [];

      attToSave.forEach(file => {
        delete file.preview;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("title", file?.name);
        formData.append("content", file?.size);
        attachmentsToSave.push(axios.post(`users/${clientId}/others-attachments`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }));
      })

      const resAttSaved = await Promise.all(attachmentsToSave);

      resAttSaved.forEach(item => {
        const tempAttachment = {
          id: item.data.data.id,
          preview: item.data.data.url,
          previewBase64: item.data.data.encoded_raw_content,
          mimeType: item.data.data.mime_type,
          name: item.data.data.title,
          size: item.data.data.content,
          status: 'saved',
        };

        attSaved.push(tempAttachment);
      });
    }

    if (attToDelete.length > 0) {
      const attachmentsToDelete = [];
      attToDelete.forEach(file => {
        attachmentsToDelete.push(axios.delete(`users/${clientId}/others-attachments/${file.id}`));
      });
      await Promise.all(attachmentsToDelete);
    }

    const respond = {
      id: currentClient.id,
      status_category_id: data.status_category_id,
      name: data.name,
      email: data.email,
      ic_no: data.ic_no,
      dob: _.isDate(data.dob) ? format(data.dob, 'yyyy-MM-dd 00:00:00') : data.dob,
      mobile_phone: data.mobile_phone,
      home_phone: data.home_phone,
      gender: data.gender,
      address: data.address,
      state: data.state,
      locality_id: data.locality_id,
      postcode: data.postcode,
      smoker_category_id: data.smoker_category_id,
      number_of_cases: data.number_of_cases,
      occupation_category_id: data.occupation_category_id,
      occupation_category_remark: data.occupation_category_remark,
      remark: _.cloneDeep(data.remark),
      attachments: attSaved,
      servicing_agent: data.servicing_agent,
    };

    return respond;
  }


  const deleteClient = async (clientId) => {
    return await axios.delete(`users/${clientId}`);
  }

  const setClientPageAndFilterTag = (data) => {
    dispatch({
      type: 'SET_CLIENT_PAGE_AND_FILTER_TAG',
      payload: data ? { ...data } : null
    });
  }

  const setSubordinatePageAndFilterTag = (data) => {
    dispatch({
      type: 'SET_SUBORDINATE_PAGE_AND_FILTER_TAG',
      payload: data ? { ...data } : null
    });
  }

  const setProductPageAndFilterTag = (data) => {
    dispatch({
      type: 'SET_PRODUCT_PAGE_AND_FILTER_TAG',
      payload: data ? { ...data } : null
    });
  }

  const getPendingApplicationList = async (page) => {
    const limit = 10;
    const params = `?_limit=${limit}&page=${page}&order=desc&role_ids[]=3&role_ids[]=4&status_category_id=12`;
    const res = await axios.get(`/users${params}`);

    const pendingApplicationList = res.data.data.map(item => {
      return {
        id: item.id,
        name: item.name,
        email: item.email,
        username: item.username,
        role: item.role.title,
        mobile_phone: item.mobile_phone,
        status: item.status.title,
      }
    });

    return { data: pendingApplicationList, meta: res.data.meta };
  }

  const getPendingApplicationAccount = async (pendAppId) => {
    const res = await axios.get(`/users/${pendAppId}`);
    const pendAppObject = res.data.data;
    const currentPendApp = {
      id: pendAppObject.id,
      name: pendAppObject.name,
      email: pendAppObject.email,
      username: pendAppObject.username,
      role: pendAppObject.role,
      mobile_phone: pendAppObject.mobile_phone,
      status_category_id: pendAppObject.status.id.toString(),
    };

    return currentPendApp;
  }

  const getApplicableRole = async () => {
    const res = await axios.get('/applicable-roles?_limit=-1');

    dispatch({
      type: 'SET_APPLICABLE_ROLE',
      payload: res.data.data
    });

    return res.data.data;
  };


  const getAssignableStaffs = async () => {
    if (cancel) {
      cancel()
    }
    const limit = 15;
    // const params = `?_limit=${limit}&page=${1}&order=desc&role_ids[]=1&role_ids[]=2&role_ids[]=3&role_ids[]=4&role_ids[]=5&status_category_id=13`;
    const params = `?_limit=${limit}&page=${1}&hierachy_level=-1&is_admin=1`;

    const res = await axios.get(`/user/supervisor-children${params}`, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    });

    const assignableStaffs = res.data.data.map(item => {
      return {
        id: item.id,
        name: item.name,
        username: item.username,
        role: item.role.title,
      }
    });
    return assignableStaffs;
  }

  const approveApplication = async (data, currentPendApp) => {
    const newRole = data.role_id;
    const currentRole = currentPendApp.role.id;

    if (currentRole !== newRole) {
      const newInfo = {
        name: data?.name,
        role_id: data.role_id,
        email: data?.email,
        username: data.username,
        mobile_phone: data.mobile_phone,
      }
      await axios.put(`/users/${data.id}`, newInfo);
    }

    if (data.supervisor) {
      await axios.post(`/users/${data.id}/supervisor-parent`, { parent_user_id: data.supervisor.id });
    }

    await axios.put(`/users/${data.id}/status`, { status_category_id: 13 });

  }

  const deleteApplication = async (id) => {
    return await axios.delete(`users/${id}/force-delete`);
  }

  const getSubordinateList = async (page, filterTag, is_admin) => {
    let filterParams = '';
    filterTag.forEach(tag => {
      filterParams += `&${tag.key}=${tag.value}`
    });
    const isAdminParams = user.is_admin ? '&include_self=1&hierachy_level=-1&is_admin=1' : '';
    const limit = 10;
    const params = `?_limit=${limit}&page=${page}&order=desc${filterParams}${isAdminParams}`;
    const res = await axios.get(`/user/supervisor-children${params}`);

    const subordinateList = res.data.data.map(item => {
      return {
        id: item.id,
        name: item.name,
        email: item.email,
        username: item.username,
        mobile_phone: item.mobile_phone,
        supervisor: item.supervisor?.name || '',
        supervisorUsername: item.supervisor?.username || '',
        is_activated: item.is_activated ? 'Yes' : 'No',
        status: item.status.title,
      }
    });

    return { data: subordinateList, meta: res.data.meta };;
  }


  const getSubordinate = async (SubordinateId) => {
    const res = await axios.get(`/users/${SubordinateId}`);
    let resSupervisor;
    try {
      resSupervisor = await axios.get(`/users/${SubordinateId}/supervisor-parent`);
    } catch (error) {
      if (error.message === "User's supervisor parent not found.") {
        resSupervisor = null;
      } else {
      }
    }

    const subordinateObject = res.data.data;

    const currentSubordinate = {
      id: subordinateObject.id,
      status_category_id: subordinateObject.status.id.toString(),
      name: subordinateObject.name,
      username: subordinateObject.username || '',
      email: subordinateObject.email || '',
      dob: subordinateObject.dob || '',
      mobile_phone: subordinateObject.mobile_phone || '',
      home_phone: subordinateObject.home_phone || '',
      gender: subordinateObject.gender || '',
      address: subordinateObject.featured_billing_location?.address || '',
      state: subordinateObject.featured_billing_location?.locality.state.id.toString() || '',
      locality_id: subordinateObject.featured_billing_location?.locality.id.toString() || '',
      postcode: subordinateObject.featured_billing_location?.postcode || '',
      role_id: subordinateObject.role?.id || '',
      supervisor: resSupervisor ? resSupervisor.data.data : null
    };

    return currentSubordinate;
  }

  const saveSubordinate = async (data, currentSubordinate) => {
    // let role = currentSubordinate.role;

    const newInfo = {
      role_id: data.role_id,
      status_category_id: data.status_category_id,
      name: data.name,
      email: data.email,
      username: data.username,
      dob: _.isDate(data.dob) ? format(data.dob, 'yyyy-MM-dd 00:00:00') : data.dob,
      mobile_phone: data.mobile_phone,
      home_phone: data.home_phone,
      gender: data.gender,
    }

    const currentInfo = {
      role_id: currentSubordinate.role_id,
      status_category_id: currentSubordinate.status_category_id,
      name: currentSubordinate.name,
      email: currentSubordinate.email,
      username: currentSubordinate.username,
      dob: currentSubordinate.dob,
      mobile_phone: currentSubordinate.mobile_phone,
      home_phone: currentSubordinate.home_phone,
      gender: currentSubordinate.gender,
    }

    const newAddress = {
      address: data.address,
      postcode: data.postcode,
      locality_id: data.locality_id,
    }

    const currentAddress = {
      address: currentSubordinate.address,
      postcode: currentSubordinate.postcode,
      locality_id: currentSubordinate.locality_id,
    }


    if (!_.isEqual(newInfo, currentInfo)) {
      await axios.put(`/users/${currentSubordinate.id}`, newInfo);
    }

    if (!_.isEqual(newAddress, currentAddress)) {
      await axios.put(`/users/${currentSubordinate.id}/featured-billing-location`, newAddress);
    }

    // if (data.status_category_id !== currentSubordinate.status_category_id) {
    //   await axios.put(`/users/${currentSubordinate.id}/status`, { status_category_id: data.status_category_id });
    // }

    // if (data.role_id !== currentSubordinate.role_id) {
    //   const newInfo = {
    //     name: currentSubordinate?.name,
    //     role_id: data.role_id,
    //     email: currentSubordinate?.email,
    //     username: currentSubordinate.username,
    //     mobile_phone: currentSubordinate.mobile_phone,
    //   }
    //   const res = await axios.put(`/users/${currentSubordinate.id}`, newInfo);
    //   role = res.data.data.role;
    // }

    if (data.supervisor?.id !== currentSubordinate.supervisor?.id) {
      if(data.supervisor !== null) {
        await axios.put(`/users/${currentSubordinate.id}/supervisor-parent`, { parent_user_id: data.supervisor.id });
      } else {
        await axios.delete(`/users/${currentSubordinate.id}/supervisor-parent`);
      }
    }

    const updatedSubordinate = {
      // ...currentSubordinate,
      // status_category_id: data.status_category_id,
      // role: role,
      // supervisor: data.supervisor,
      id: currentSubordinate.id,
      ...data,
    }

    return updatedSubordinate;
  }

  const donwloadAttachment = async (url, blobUrl) => {
    const link = document.createElement("a");
    if (url) {
      const res = await axios.get(url);
      const file = res.data.data;

      link.download = file.title;
      link.href = `data:${file.mimeType};base64,${file.encoded_raw_content}`

    } else {
      link.href = blobUrl.url;
      link.download = blobUrl.name;
    }

    link.target = "_blank";
    link.click();
  }

  const saveExam = async (data) => {
    const { video, attachments } = data;

    const attSaved = _.filter(attachments, { 'status': 'saved' });

    const attToSave = _.filter(attachments, { 'status': 'new' });

    const attToDelete = _.filter(attachments, { 'status': 'toRemove' });

    if (attToSave.length > 0) {
      const attachmentsToSave = [];

      attToSave.forEach(file => {
        delete file.preview;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("title", file?.name);
        formData.append("content", file?.size);
        attachmentsToSave.push(axios.post("attachments", formData, { headers: { 'Content-Type': 'multipart/form-data' } }));
      });

      const resAttSaved = await Promise.all(attachmentsToSave);

      resAttSaved.forEach(item => {
        const tempAttachment = {
          id: item.data.data.id,
          url: item.data.data.url,
          mimeType: item.data.data.mime_type,
          name: item.data.data.title,
          size: item.data.data.content,
          status: 'saved',
        };

        attSaved.push(tempAttachment);
      });
    }

    if (attToDelete.length > 0) {
      for (const i in attToDelete) {
        try { //added try catch no matter fail or success still go through
          await axios.delete(`attachments/${attToDelete[i].id}`);
        } catch (error) {
        }
      }
    }

    const params = {
      content: JSON.stringify({
        video: video,
        attachments: attSaved,
      })
    }

    const res = await axios.put(`/exam-posts/1`, params);
    const content = res.data.data.content;
    const respond = {
      video: content.video ? content.video : [],
      attachments: content.attachments ? content.attachments : [],
    }
    return respond;
  }

  const getExam = async () => {
    const res = await axios.get(`/special-exam-post`);
    const content = res.data.data.content;
    const respond = {
      video: content.video ? content.video : [],
      attachments: content.attachments ? content.attachments : [],
    }
    return respond;
  }

  const getProductCategories = async (saveState = false) => {
    const res = await axios.get('/custom-categories?_limit=-1&category_id=101');
    let categories = [];

    if (!saveState) {
      categories = res.data.data.map(item => ({
        id: item.id,
        title: item.title,
        can_delete: item.can_delete,
        status: "saved",
        modified: 0,
      }));
    } else {
      categories = res.data.data.map(item => ({
        id: item.id,
        title: item.title,
      }));

      dispatch({
        type: 'SET_CATEGORIES',
        payload: categories,
      });
    }

    return categories;
  }

  const saveProductCategories = async (categories) => {
    const catSaved = _.filter(categories, { 'status': 'saved', 'modified': 0 });

    const catToModify = _.filter(categories, { 'status': 'saved', 'modified': 1 });

    const catToSave = _.filter(categories, { 'status': 'new' });

    const catToDelete = _.filter(categories, { 'status': 'toRemove' });

    if (catToModify.length > 0) {
      for (const i in catToModify) {
        const params = { title: catToModify[i].title }
        const resCatModified = await axios.put(`/custom-categories/${catToModify[i].id}`, params);
        const item = resCatModified.data.data;
        catSaved.push({
          id: item.id,
          title: item.title,
          can_delete: item.can_delete,
          status: "saved",
          modified: 0,
        });
      }
    }

    if (catToSave.length > 0) {
      for (const i in catToSave) {
        const params = { title: catToSave[i].title, category_id: 101 }
        const resCatSaved = await axios.post('/custom-categories', params);
        const item = resCatSaved.data.data;
        catSaved.push({
          id: item.id,
          title: item.title,
          can_delete: item.can_delete,
          status: "saved",
          modified: 0,
        });
      }
    }

    if (catToDelete.length > 0) {
      for (const i in catToDelete) {
        try { //added try catch no matter fail or success still go through
          await axios.delete(`/custom-categories/${catToDelete[i].id}`);
        } catch (error) {
        }
      }
    }

    const sortedCat = _.sortBy(catSaved, "id");
    return sortedCat;
  }

  const getProductList = async (page, filterTag) => {
    let tempParams = '';
    filterTag.forEach(tag => {
      tempParams += `&${tag.key}=${tag.value}`
    });
    const limit = 10;
    const params = `?_limit=${limit}&page=${page}${tempParams}`;
    const res = await axios.get(`/product-posts${params}`);

    const tempProductList = res.data.data.map(item => {
      return {
        id: item.id,
        title: item.title,
        category: item.custom_category ? item.custom_category.title : "",
        status: item.status ? item.status.title : "",
        created_user_id: item.created_user ? item.created_user.id : "",
        created_user_name: item.created_user ? item.created_user.name : "",
        created_at: format(parseISO(item.created_at), 'yyyy-MM-dd HH:mm:ss'),
      }
    });

    if (!state.categories || state.categories.length === 0) {
      await getProductCategories(true);
    }

    const respond = {
      data: tempProductList,
      meta: res.data.meta,
    }

    return respond;
  }

  const getProduct = async (productId, skipCategories = false) => {
    const res = await axios.get(`/product-posts/${productId}`);
    const data = res.data.data;
    const productBasic = {
      id: data.id,
      title: data.title,
      status_category_id: data.status?.id.toString() || "",
      custom_category_id: data.custom_category?.id.toString() || "",
      created_user: data.created_user || null,
    }

    const product = data.content ? (_.isString(data.content) ? JSON.parase(data.content) : data.content) : []

    if ((!state.categories || state.categories.length === 0) && skipCategories === false) {
      await getProductCategories(true);
    }

    return { productBasic, product };
  }

  const saveNewProduct = async (productBasic, product) => {
    let tempProduct = _.cloneDeep(product);

    for (let i in tempProduct) {
      if (tempProduct[i].type === DROPDOWN_VIEW) {
        for (let j in tempProduct[i].list) {
          tempProduct[i].list[j].status = 'saved';

          if (tempProduct[i].list[j].document) {
            const document = tempProduct[i].list[j].document;
            const formData = new FormData();
            formData.append("file", document);
            formData.append("title", document?.name);
            formData.append("content", document?.size);

            delete tempProduct[i].list[j].preview;

            try {
              const res = await axios.post("attachments", formData, { headers: { 'Content-Type': 'multipart/form-data' } });
              const savedFile = res.data.data;
              const tempAttachment = {
                id: savedFile.id,
                url: savedFile.url,
                mimeType: savedFile.mime_type,
                name: savedFile.title,
                size: savedFile.content,
              };
              tempProduct[i].list[j].document = tempAttachment;
            } catch (error) {
              tempProduct[i].list[j].document = "";
            }
          }
          tempProduct[i].list[j].modified = 0;
        }
      } else if (tempProduct[i].type === DROPDOWN_DOC_VIEW) {
        for (let j in tempProduct[i].list) {
          tempProduct[i].list[j].status = 'saved';

          if (tempProduct[i].list[j].cover) {
            const cover = tempProduct[i].list[j].cover;
            const formData = new FormData();
            formData.append("file", cover);
            formData.append("title", cover?.name);
            formData.append("content", cover?.size);
            formData.append("is_public", 1);

            delete tempProduct[i].list[j].coverPreview;

            try {
              const res = await axios.post("attachments", formData, { headers: { 'Content-Type': 'multipart/form-data' } });
              const savedFile = res.data.data;
              const tempAttachment = {
                id: savedFile.id,
                url: savedFile.url,
                mimeType: savedFile.mime_type,
                name: savedFile.title,
                size: savedFile.content,
              };
              tempProduct[i].list[j].cover = tempAttachment;
            } catch (error) {
              tempProduct[i].list[j].cover = "";
            }
          }

          if (tempProduct[i].list[j].document) {
            const document = tempProduct[i].list[j].document;
            const formData = new FormData();
            formData.append("file", document);
            formData.append("title", document?.name);
            formData.append("content", document?.size);

            delete tempProduct[i].list[j].preview;

            try {
              const res = await axios.post("attachments", formData, { headers: { 'Content-Type': 'multipart/form-data' } });
              const savedFile = res.data.data;
              const tempAttachment = {
                id: savedFile.id,
                // url: savedFile.url,
                mimeType: savedFile.mime_type,
                name: savedFile.title,
                size: savedFile.content,
              };

              tempProduct[i].list[j].document = tempAttachment;
            } catch (error) {
              tempProduct[i].list[j].document = "";
            }
          }
          tempProduct[i].list[j].modified = 0;
        }
      }
    }

    const params = {
      title: productBasic.title,
      content: JSON.stringify(tempProduct),
      status_category_id: productBasic.status_category_id
    }

    const productRes = await axios.post('/product-posts', params);

    if (productBasic.custom_category_id) {
      const custCatParams = { custom_category_id: productBasic.custom_category_id };
      await axios.post(`/product-posts/${productRes.data.data.id}/custom-category`, custCatParams);
    }

    const data = productRes.data.data;

    const productBasicTemp = {
      title: data.title,
      status_category_id: data.status?.id.toString() || "",
      custom_category_id: data.custom_category?.id.toString() || "",
      custom_category_id_saved: data.custom_category?.id.toString() || "",
    }

    const productTemp = data.content ? (_.isString(data.content) ? JSON.parase(data.content) : data.content) : []

    return { productBasic: productBasicTemp, product: productTemp };
  }

  const saveEditProduct = async (productBasic, product, currentProduct, attachmentsToDelete = []) => {
    if (productBasic.custom_category_id_saved !== productBasic.custom_category_id) {
      const custCatParams = { custom_category_id: productBasic.custom_category_id };
      await axios.put(`/product-posts/${currentProduct.productBasic.id}/custom-category`, custCatParams);
    }

    let tempProduct = _.cloneDeep(product);

    for (let i in tempProduct) {
      // if (tempProduct[i].type === "VideoView") {
      // if (tempProduct[i].type === "ContentView") {
      if (tempProduct[i].type === DROPDOWN_VIEW) {
        for (let j in tempProduct[i].list) {
          tempProduct[i].list[j].status = 'saved';

          if (tempProduct[i].list[j].document && !Boolean(tempProduct[i].list[j].document?.id)) {
            const document = tempProduct[i].list[j].document;
            const formData = new FormData();
            formData.append("file", document);
            formData.append("title", document?.name);
            formData.append("content", document?.size);

            delete tempProduct[i].list[j].preview;

            try {
              const res = await axios.post("/attachments", formData, { headers: { 'Content-Type': 'multipart/form-data' } });
              const savedFile = res.data.data;
              const tempAttachment = {
                id: savedFile.id,
                url: savedFile.url,
                mimeType: savedFile.mime_type,
                name: savedFile.title,
                size: savedFile.content,
              };
              tempProduct[i].list[j].document = tempAttachment;
            } catch (error) {
              tempProduct[i].list[j].document = "";
            }
          }
        }
      } else if (tempProduct[i].type === DROPDOWN_DOC_VIEW) {
        for (let j in tempProduct[i].list) {
          tempProduct[i].list[j].status = 'saved';

          if (tempProduct[i].list[j].cover && !Boolean(tempProduct[i].list[j].cover?.id)) {
            const cover = tempProduct[i].list[j].cover;
            const formData = new FormData();
            formData.append("file", cover);
            formData.append("title", cover?.name);
            formData.append("content", cover?.size);
            formData.append("is_public", 1);

            delete tempProduct[i].list[j].coverPreview;

            try {
              const res = await axios.post("/attachments", formData, { headers: { 'Content-Type': 'multipart/form-data' } });
              const savedFile = res.data.data;
              const tempAttachment = {
                id: savedFile.id,
                url: savedFile.url,
                mimeType: savedFile.mime_type,
                name: savedFile.title,
                size: savedFile.content,
              };
              tempProduct[i].list[j].cover = tempAttachment;
            } catch (error) {
              tempProduct[i].list[j].cover = "";
            }
          }

          if (tempProduct[i].list[j].document && !Boolean(tempProduct[i].list[j].document?.id)) {
            const document = tempProduct[i].list[j].document;
            const formData = new FormData();
            formData.append("file", document);
            formData.append("title", document?.name);
            formData.append("content", document?.size);

            delete tempProduct[i].list[j].preview;

            try {
              const res = await axios.post("attachments", formData, { headers: { 'Content-Type': 'multipart/form-data' } });
              const savedFile = res.data.data;
              const tempAttachment = {
                id: savedFile.id,
                url: savedFile.url,
                mimeType: savedFile.mime_type,
                name: savedFile.title,
                size: savedFile.content,
              };
              tempProduct[i].list[j].document = tempAttachment;
            } catch (error) {
              tempProduct[i].list[j].document = "";
            }
          }
        }
      }
    }

    const params = {
      title: productBasic.title,
      content: JSON.stringify(tempProduct),
      status_category_id: productBasic.status_category_id
    }

    const productRes = await axios.put(`/product-posts/${currentProduct.productBasic.id}`, params);

    for (let i in attachmentsToDelete) {
      try { //added try catch no matter fail or success still go through
        await axios.delete(`/attachments/${attachmentsToDelete[i]}`);
      } catch (error) {
      }
    }

    const data = productRes.data.data;

    const productBasicTemp = {
      title: data.title,
      status_category_id: data.status?.id.toString() || "",
      custom_category_id: data.custom_category?.id.toString() || "",
      custom_category_id_saved: data.custom_category?.id.toString() || "",
    }

    const productTemp = data.content ? (_.isString(data.content) ? JSON.parase(data.content) : data.content) : []

    return { productBasic: productBasicTemp, product: productTemp };
  }

  const deleteProduct = async (productId, attachmentsToDelete = []) => {
    await axios.delete(`/product-posts/${productId}/custom-category`);
    await axios.delete(`/product-posts/${productId}`);

    for (let i in attachmentsToDelete) {
      try {
        await axios.delete(`/attachments/${attachmentsToDelete[i]}`);
      } catch (error) {
      }
    }

    return {};
  }

  const getLoginPortal =  async () => {
    const res = await axios.get(`/login-portal-others-post`);
    return res.data.data.content.portals;
  }


  return (
    <AppContext.Provider
      value={{
        state: {
          ...state
        },
        actions: {
          getClient,
          getClientList,
          saveNewClient,
          saveEditClient,
          deleteClient,
          setClientListHeader,
          setClientPageAndFilterTag,
          getPendingApplicationList,
          getPendingApplicationAccount,
          getApplicableRole,
          getAssignableStaffs,
          approveApplication,
          deleteApplication,
          getSubordinateList,
          setSubordinateListHeader,
          setSubordinatePageAndFilterTag,
          getSubordinate,
          saveSubordinate,
          donwloadAttachment,
          saveExam,
          getExam,
          getProductCategories,
          saveProductCategories,
          getProductList,
          getProduct,
          setProductListHeader,
          setProductPageAndFilterTag,
          saveNewProduct,
          saveEditProduct,
          deleteProduct,
          getLoginPortal,
        }
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider };