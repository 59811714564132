export const DRAWER_WIDTH = 260;

export const DASHBOARD_HEADER_MOBILE = 64;
export const DASHBOARD_HEADER_DESKTOP = 92;
export const DASHBOARD_NAVBAR_WIDTH = 280;
export const DASHBOARD_NAVBAR_COLLAPSE_WIDTH = 88;

export const DASHBOARD_NAVBAR_ROOT_ITEM_HEIGHT = 48;
export const DASHBOARD_NAVBAR_SUB_ITEM_HEIGHT = 40;
export const DASHBOARD_NAVBAR_ICON_ITEM_SIZE = 22;

export const MAIN_HEADER_DESKTOP = 88;
export const MAIN_HEADER_MOBILE = 64;

// SETTINGS
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'dark',
  themeDirection: 'ltr',
  themeColorPresets: 'orange',
  themeStretch: false,
};

export const defaultSettingClientListHeader = [
  { key: "id", label: "ID", status: true },
  { key: "name", label: "Name", status: true },
  { key: "email", label: "Email", status: true },
  { key: "mobile_phone", label: "Mobile Phone", status: true },
  { key: "home_phone", label: "Home Phone", status: false },
  { key: "dob", label: "Date Of Birth", status: false },
  { key: "ic_no", label: "IC Number", status: false },
  { key: "age", label: "Age", status: true },
  { key: "gender", label: "Gender", status: true },
  { key: "address", label: "Address", status: false },
  { key: "state", label: "State", status: false },
  { key: "locality", label: "City", status: true },
  { key: "postcode", label: "Postcode", status: false },
  { key: "smoker", label: "Smoker", status: true },
  { key: "occupation", label: "Occupation", status: false },
  { key: "number_of_cases", label: "No of Cases", status: true },
  { key: "servicing_agent", label: "Agent", status: false },
  { key: 'status', label: 'Status', status: false },
  { key: "action", label: "Action", status: true },
]

export const defaultSettingSubordinateListHeader = [
  { key: "id", label: "ID", status: true },
  { key: "name", label: "Name", status: true },
  { key: "email", label: "Email", status: true },
  { key: "username", label: "Agent Code", status: true },
  { key: "mobile_phone", label: "Mobile Phone", status: true },
  { key: "supervisor", label: "Supervisor", status: true },
  { key: "supervisorUsername", label: "Supervisor Agent Code", status: true },
  // { key: 'is_activated', label: 'Is Activated', status: true },
  { key: "status", label: "Status", status: true },
  { key: "action", label: "Action", status: true },
]

export const defaultSettingPendAppListHeader = [
  { key: "id", label: "ID", status: true },
  { key: "name", label: "Name", status: true },
  { key: "username", label: "Agent Code", status: true },
  { key: "email", label: "Email", status: true },
  { key: "mobile_phone", label: "Mobile Phone", status: true },
  { key: "role", label: "Role", status: true},
  { key: 'status', label: 'Status', status: false },
  { key: "action", label: "Action", status: true },
]

export const defaultSettingProductListHeader = [
  { key: "id", label: "ID", status: true },
  { key: "title", label: "Title", status: true },
  { key: "category", label: "Category", status: true },
  { key: 'status', label: 'Status', status: true },
  { key: "created_user_id", label:'Created By User Id', status: true },
  { key: "created_user_name", label:'Created By', status: true },
  { key: "created_at", label: "Created At", status: true },
  { key: "action", label: "Action", status: true },
]

