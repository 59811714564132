import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { format, addHours } from 'date-fns'
import _ from 'lodash';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {}
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  newPassword: () => Promise.resolve(),
  resendVerificationEmail: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  getProfile: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  getApplicableRole: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          let user;
          
          const refreshedTimestamp = localStorage.getItem('logged_user_profile_timestamp') || 0;
          const timestamp = addHours(new Date(), -1).getTime();

          if(Number(refreshedTimestamp) > timestamp) {
            user = JSON.parse(window.localStorage.getItem('logged_user')); 

          } else {
            const resUser = await axios.get('/user');
            const userTemp = resUser.data.data;
            user = {
              displayName: userTemp.name,
              email: userTemp.email,
              id: userTemp.id,
              is_admin: userTemp.is_in_admin_group,
              is_pre_agent: userTemp.role.name === 'pre_agent',
              username: userTemp.username,
              role: {
                id: userTemp.role.id,
                name: userTemp.role.name,
                title: userTemp.role.title,
              },
            }

            localStorage.setItem('logged_user_profile_timestamp', new Date().getTime());
          }

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password, remember) => {
    const resLogin = await axios.post('/sanctum/token', {
      email,
      password
    });

    setSession(resLogin.data);

    const resUser = await axios.get('/user');
    const userTemp = resUser.data.data;
    const user = {
      displayName: userTemp.name,
      email: userTemp.email,
      id: userTemp.id,
      is_admin: userTemp.is_in_admin_group,
      is_pre_agent: userTemp.role.name === 'pre_agent',
      username: userTemp.username,
      role: {
        id: userTemp.role.id,
        name: userTemp.role.name,
        title: userTemp.role.title,
      },
    }

    localStorage.setItem('logged_user', JSON.stringify(user));
    localStorage.setItem('logged_user_profile_timestamp', new Date().getTime());

    if(remember) {
      localStorage.setItem('logged_user_dm_uname', email);
    } else {
      localStorage.removeItem("logged_user_dm_uname");
    }
  
    dispatch({
      type: 'LOGIN',
      payload: {
        user: user
      }
    });
  };

  const register = async (name, email, username, mobile_phone, password, password_confirmation, role_id) => {
    await axios.post('/register', {
      name,
      email,
      username,
      mobile_phone,
      password,
      password_confirmation,
      role_id
    });
  };

  const logout = async () => {
    try {
      await axios.delete('/sanctum/token');
    } catch (error) {

    }
    
    localStorage.removeItem("logged_user");
   
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = async (email) => {
    await axios.post('/forgot-password', { email });
  };

  const newPassword = async (password, password_confirmation, token, email) => {
    await axios.post(`/reset-password?token=${token}&email=${decodeURIComponent(email)}`, { 
      password,
      password_confirmation
     });
  };

  const resendVerificationEmail = async (email) => {
    await axios.post('/email/verification-notification', { email });
  };

  const updateProfile = async (data, currentUser) => {
    const newInfo = {
      name: data.name,
      email: data.email,
      dob: _.isDate(data.dob) ? format(data.dob, 'yyyy-MM-dd 00:00:00'): data.dob,
      mobile_phone: data.mobile_phone,
      home_phone: data.home_phone,
      gender: data.gender,
    }

    const currentInfo = {
      name: currentUser.name,
      email: currentUser.email,
      dob: currentUser.dob,
      mobile_phone: currentUser.phoneNumber || '',
      home_phone: currentUser.homePhoneNumber || '',
      gender: currentUser.gender,
    }

    const newAddress = {
      address: data.address, 
      postcode: data.postcode, 
      locality_id: data.locality_id,
    }

    const currentAddress = {
      address: currentUser.address, 
      postcode: currentUser.postcode, 
      locality_id: currentUser.locality_id,
    }

    if(!_.isEqual(newInfo, currentInfo)) {
      await axios.put(`/user/profile-information`, newInfo);
    } 

    if(!_.isEqual(newAddress, currentAddress)) {
      await axios.put(`/users/${state.user.id}/featured-billing-location`, newAddress);
    } 

    const respond = {
      id: currentUser.id,
      status_category_id: data.status_category_id,
      name: data.name,
      username: data.username,
      email: data.email,
      mobile_phone: data.mobile_phone,
      home_phone: data.home_phone,
      gender: data.gender,
      address: data.address,
      state: data.state,
      locality_id: data.locality_id,
      postcode: data.postcode,
    };

    return respond;
  };

  const getProfile =  async () => {
    const res = await axios.get(`/user`);
    // const resProfileAttachments = await axios.get(`/users/${state.user.id}/profile-attachments`);
    let resSupervisor;
    try {
      resSupervisor = await axios.get(`/users/${state.user.id}/supervisor-parent`);
    } catch (error) {
      if(error.message === "User's supervisor parent not found.") {
        resSupervisor = null;
      }
    }

    // const tempProfileAvatar = resProfileAttachments.data.data.length > 0 ? resProfileAttachments.data.data[resProfileAttachments.data.data.length - 1] : null;
    // const profileAvatar = tempProfileAvatar ? {
    //   id: tempProfileAvatar.id,
    //   preview: tempProfileAvatar.url,
    //   previewBase64: tempProfileAvatar.encoded_raw_content,
    //   mimeType: tempProfileAvatar.mime_type,
    //   name: tempProfileAvatar.title,
    //   size: tempProfileAvatar.content,
    //   status: 'saved',
    //   idToDelete: tempProfileAvatar.id,
    // } : null;

    const userObject = res.data.data;

    const currentUser = {
      id: userObject.id,
      // avatar: profileAvatar || '',
      status: userObject.status.id.toString(),
      name: userObject.name,
      username: userObject.username || '',
      email: userObject.email || '',
      dob: userObject.dob || null,
      mobile_phone: userObject.mobile_phone  || '',
      home_phone: userObject.home_phone  || '',
      gender: userObject.gender || '',
      address: userObject.featured_billing_location?.address || '',
      state: userObject.featured_billing_location?.locality.state.id.toString() || '', 
      locality_id: userObject.featured_billing_location?.locality.id.toString() || '',
      postcode: userObject.featured_billing_location?.postcode || '',
    };

    const data = {
      currentUser,
      supervisor: resSupervisor ? resSupervisor.data.data : null,
      role: userObject.role
    }

    return data;
  };

  const changePassword = async (data) => {
    await axios.put('/user/password', {
      current_password: data.current_password,
      password: data.password,
      password_confirmation: data.password_confirmation
    });

    logout();
  };

  const getApplicableRole = async () => {
    const res = await axios.get('/applicable-roles?_limit=-1');
    return res.data.data;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        newPassword,
        resendVerificationEmail,
        updateProfile,
        getProfile,
        changePassword,
        getApplicableRole
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
