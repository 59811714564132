import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Container, Typography } from '@mui/material';
import { PATH_AUTH } from '../../routes/paths';
import { LoginForm } from '../../sections/auth/login';

const RootStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("/static/images/background.png")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function Login() {
  return (
    <RootStyle>
      <Container maxWidth="sm">
        <ContentStyle >
          <Box sx={{ mb: 3 }}>
            <Typography variant="h4" gutterBottom sx={{ color: '#FFFFFF' }}>
              Sign in to Dream Map
            </Typography>
            <Typography sx={{ color: '#FFFFFF' }}>Enter your details below.</Typography>
          </Box>
          <LoginForm />
          <Typography variant="body2" align="center" sx={{ mt: 3, color: '#FFFFFF'}}>
            Don’t have an account?&nbsp;
            <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
              Get started
            </Link>
          </Typography>
          <Typography variant="body2" sx={{ mt: 0.5, textAlign: 'center', color: '#FFFFFF' }}>
            Already have an account but not yet verify?&nbsp;
            <Link variant="subtitle2" to={PATH_AUTH.resendVerificationEmail} component={RouterLink}>
              Resend verification email
            </Link>
          </Typography>
        </ContentStyle >
      </Container>
    </RootStyle>
  );
}