function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  resendVerificationEmail: path(ROOTS_AUTH, '/resend-verification-email'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  user: {
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  client: {
    root: path(ROOTS_DASHBOARD, '/client'),
    list: path(ROOTS_DASHBOARD, '/client/list'),
    new: path(ROOTS_DASHBOARD, '/client/new'),
    editById: path(ROOTS_DASHBOARD, '/client/:id/edit'),
  },
  subordinate: {
    root: path(ROOTS_DASHBOARD, '/subordinate'),
    list: path(ROOTS_DASHBOARD, '/subordinate/list'),
    editById: path(ROOTS_DASHBOARD, '/subordinate/:id/edit'),
  },
  pendingApplication: {
    root: path(ROOTS_DASHBOARD, '/pending-application'),
    list: path(ROOTS_DASHBOARD, '/pending-application/list'),
    editById: path(ROOTS_DASHBOARD, '/pending-application/:id/edit'),
  },
  exam: {
    root: path(ROOTS_DASHBOARD, '/exam'),
    view: path(ROOTS_DASHBOARD, '/exam/view'),
    edit: path(ROOTS_DASHBOARD, '/exam/edit'),
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    new: path(ROOTS_DASHBOARD, '/product/new'),
    editById: path(ROOTS_DASHBOARD, '/product/:id/edit'),
    categories: path(ROOTS_DASHBOARD, '/product/categories'),
  },
  policy: {
    root: path(ROOTS_DASHBOARD, '/policy'),
    list: path(ROOTS_DASHBOARD, '/policy/list'),
    new: path(ROOTS_DASHBOARD, '/policy/new'),
    editById: path(ROOTS_DASHBOARD, '/policy/:id/edit'),
  },
  loginPortal: {
    root: path(ROOTS_DASHBOARD, '/login-portal'),
  }
}


