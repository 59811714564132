import axios from 'axios';

export const CancelToken = axios.CancelToken;
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN_URL,
  headers: {
    withCredentials : true
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if(error?.response?.status === 401 && error?.response?.data?.message === "Unauthenticated.") {
      localStorage.removeItem("logged_user");
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
      window.location.href = "/auth/login";
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default axiosInstance;
