import Router from './routes';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ScrollToTop from './components/ScrollToTop';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

function App() {
  
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <NotistackProvider>
          <MotionLazyContainer>
            <GlobalStyles />
            <ScrollToTop />
            <Router />
          </MotionLazyContainer>
        </NotistackProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

export default App;
