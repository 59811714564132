import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Auth Routes
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          )
        },
        {
          path: 'new-password',
          element: (
            <GuestGuard>
              <NewPassword />
            </GuestGuard>
          )
        },
        {
          path: 'resend-verification-email',
          element: (
            <GuestGuard>
              <ResendVerificationEmail />
            </GuestGuard>

          )
        },
      ]
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace={true} />, index: true },
        { path: 'app', element: null },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/account" replace={true} />, index: true },
            { path: 'account', element: <UserAccount /> },
          ]
        },
        {
          path: 'client',
          children: [
            { element: <Navigate to="/dashboard/client/list" replace={true} />, index: true },
            { path: 'new', element: <ClientCreate /> },
            { path: ':id/edit', element: <ClientEdit /> },
            { path: 'list', element: <ClientList /> },
          ]
        },
        {
          path: 'subordinate',
          children: [
            { element: <Navigate to="/dashboard/subordinate/list" replace={true} />, index: true },
            { path: 'list', element: <SubordinateList /> },
            { path: ':id/edit', element: <SubordinateEdit /> },
          ]
        },
        {
          path: 'pending-application',
          children: [
            { element: <Navigate to="/dashboard/pending-application/list" replace={true} />, index: true },
            { path: 'list', element: <PendingApplicationList /> },
            { path: ':id/edit', element: <PendingApplicationEdit /> },
          ]
        },
        {
          path: 'product',
          children: [
            { element: <Navigate to="/dashboard/product/list" replace={true} />, index: true },
            { path: 'list', element: <ProductList /> },
            { path: 'new', element: <ProductCreate /> },
            { path: ':id/edit', element: <ProductEdit /> },
            { path: ':id/view', element: <ProductView /> },
            { path: 'categories', element: <ProductCategories /> },
          ]
        },
        {
          path: 'exam',
          children: [
            { element: <Navigate to="/dashboard/exam/view" replace={true} />, index: true },
            { path: 'view', element: <ExamView /> },
            { path: 'edit', element: <ExamEdit /> },
          ]
        },
        {
          path: 'login-portal',
          children: [
            { element: <LoginPortal />, index: true },
          ]
        }
      ]
    },
    {
      path: '*',
      element: <Navigate to="/auth/login" replace />,
      children: [
        { path: '*', element: <Navigate to="/auth/login" replace /> }
      ]
    },
  ]);
}

const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const ResendVerificationEmail = Loadable(lazy(() => import('../pages/auth/ResendVerificationEmail')));
const ClientCreate = Loadable(lazy(() => import('../pages/dashboard/ClientCreate')));
const ClientEdit = Loadable(lazy(() => import('../pages/dashboard/ClientEdit')));
const ClientList = Loadable(lazy(() => import('../pages/dashboard/ClientList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const SubordinateList = Loadable(lazy(() => import('../pages/dashboard/SubordinateList')));
const SubordinateEdit = Loadable(lazy(() => import('../pages/dashboard/SubordinateEdit')));
const PendingApplicationList = Loadable(lazy(() => import('../pages/dashboard/PendingApplicationList')));
const PendingApplicationEdit = Loadable(lazy(() => import('../pages/dashboard/PendingApplicationEdit')));
const ExamView = Loadable(lazy(() => import('../pages/dashboard/ExamView')));
const ExamEdit = Loadable(lazy(() => import('../pages/dashboard/ExamEdit')));
const ProductCategories = Loadable(lazy(() => import('../pages/dashboard/ProductCategories')));
const ProductList = Loadable(lazy(() => import('../pages/dashboard/ProductList')));
const ProductCreate = Loadable(lazy(() => import('../pages/dashboard/ProductCreate')));
const ProductEdit = Loadable(lazy(() => import('../pages/dashboard/ProductEdit')));
const ProductView =  Loadable(lazy(() => import('../pages/dashboard/ProductView')));
const LoginPortal = Loadable(lazy(() => import('../pages/dashboard/LoginPortal')));