import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name) => <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

export const navConfigAdmin = [
  {
    subheader: 'management',
    items: [
      {
        title: 'Insurance Portal',
        path: PATH_DASHBOARD.loginPortal.root,
        icon: ICONS.kanban,
      },
      {
        title: 'Subordinate',
        path: PATH_DASHBOARD.subordinate.list,
        icon: ICONS.user,
      },
      {
        title: 'Pending Application',
        path: PATH_DASHBOARD.pendingApplication.list,
        icon: ICONS.user,
      },
      {
        title: 'Product',
        path: PATH_DASHBOARD.product.root,
        icon: ICONS.booking,
        children: [
          { title: 'list', path: PATH_DASHBOARD.product.list },
          { title: 'create', path: PATH_DASHBOARD.product.new },
          { title: 'categories', path: PATH_DASHBOARD.product.categories },
        ]
      },
      {
        title: 'Exam',
        path: PATH_DASHBOARD.exam.root,
        icon: ICONS.kanban,
        children: [
          { title: 'view', path: PATH_DASHBOARD.exam.view },
          { title: 'edit', path: PATH_DASHBOARD.exam.edit },
        ]
      },
      {
        title: 'Client',
        path: PATH_DASHBOARD.client.root,
        icon: ICONS.user,
        children: [
          { title: 'list', path: PATH_DASHBOARD.client.list },
          { title: 'create', path: PATH_DASHBOARD.client.new },
        ]
      },
    ]
  },
];


export const navConfigAgent = [
  {
    subheader: 'management',
    items: [
      {
        title: 'Insurance Portal',
        path: PATH_DASHBOARD.loginPortal.root,
        icon: ICONS.kanban,
      },
      {
        title: 'Subordinate',
        path: PATH_DASHBOARD.subordinate.list,
        icon: ICONS.user,
      },
      {
        title: 'Product',
        path: PATH_DASHBOARD.product.root,
        icon: ICONS.booking,
        children: [
          { title: 'list', path: PATH_DASHBOARD.product.list },
        ]
      },
      {
        title: 'Exam',
        path: PATH_DASHBOARD.exam.root,
        icon: ICONS.kanban,
        children: [
          { title: 'view', path: PATH_DASHBOARD.exam.view },
        ]
      },
      {
        title: 'Client',
        path: PATH_DASHBOARD.client.root,
        icon: ICONS.user,
        children: [
          { title: 'list', path: PATH_DASHBOARD.client.list },
          { title: 'create', path: PATH_DASHBOARD.client.new },
        ]
      },
    ]
  },
];

export const navConfigPreAgent = [
  {
    subheader: 'general',
    items: [
      {
        title: 'Exam',
        path: PATH_DASHBOARD.exam.root,
        icon: ICONS.kanban,
        children: [
          { title: 'view', path: PATH_DASHBOARD.exam.view }
        ]
      },
    ]
  },
];


