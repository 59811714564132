export const VIDEO_VIEW = "VideoView";
export const CONTENT_VIEW = "ContentView";
export const DROPDOWN_VIEW = "DropDownView";
export const DROPDOWN_DOC_VIEW = "DropDownDocView";

export const statusProductOptions = [
  {
      "id": 6,
      "slug": "_post_statuses_draft",
      "title": "Draft",
      "content": null,
      "parent_id": 5
  },
  // {
  //     "id": 7,
  //     "slug": "_post_statuses_pending",
  //     "title": "Pending",
  //     "content": null,
  //     "parent_id": 5
  // },
  {
      "id": 8,
      "slug": "_post_statuses_published",
      "title": "Published",
      "content": null,
      "parent_id": 5
  },
  {
      "id": 9,
      "slug": "_post_statuses_withdrew",
      "title": "Withdrew",
      "content": null,
      "parent_id": 5
  }
]

export const statusOptions = [
  {
    "id": 13,
    "slug": "_user_status_activated",
    "title": "Activated",
    "content": null,
    "parent_id": 11
  },
  {
    "id": 14,
    "slug": "_user_status_deactivated",
    "title": "Deactivated",
    "content": null,
    "parent_id": 11
  },
  {
    "id": 99,
    "slug": "_user_status_prospect",
    "title": "Prospect",
    "content": null,
    "parent_id": 11
  },
  {
    "id": 100,
    "slug": "_user_status_in_progress",
    "title": "In Progress",
    "content": null,
    "parent_id": 11
  }
]

export const statusSubordinateOptions = [
  {
    "id": 13,
    "slug": "_user_status_activated",
    "title": "Activated",
    "content": null,
    "parent_id": 11
  },
  {
    "id": 14,
    "slug": "_user_status_deactivated",
    "title": "Deactivated",
    "content": null,
    "parent_id": 11
  }
]

export const smokingOptions = [
  {
    "id": 26,
    "slug": "_user_category_smoker_option_1",
    "title": "Smoker",
    "content": null,
    "parent_id": 25
  },
  {
    "id": 27,
    "slug": "_user_category_smoker_option_2",
    "title": "Non-Smoker",
    "content": null,
    "parent_id": 25
  }
]

export const occupationOptions = [
  {
    "id": 29,
    "slug": "_user_category_occupation_option_1",
    "title": "Actuarial Science/Statistics",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 30,
    "slug": "_user_category_occupation_option_2",
    "title": "Advertising/Media Planning",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 31,
    "slug": "_user_category_occupation_option_3",
    "title": "Agriculture/Forestry/Fisheries",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 32,
    "slug": "_user_category_occupation_option_4",
    "title": "Architecture/Interior Design",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 33,
    "slug": "_user_category_occupation_option_5",
    "title": "Arts/Creative/Graphics Design",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 34,
    "slug": "_user_category_occupation_option_6",
    "title": "Aviation/Aircraft Maintenance",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 35,
    "slug": "_user_category_occupation_option_7",
    "title": "Banking/Financial Services",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 36,
    "slug": "_user_category_occupation_option_8",
    "title": "Biomedical",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 37,
    "slug": "_user_category_occupation_option_9",
    "title": "Biotechnology",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 38,
    "slug": "_user_category_occupation_option_10",
    "title": "Chemistry",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 39,
    "slug": "_user_category_occupation_option_11",
    "title": "Clerical/Administrative Support",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 40,
    "slug": "_user_category_occupation_option_12",
    "title": "Corporate Strategy/Top Management",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 41,
    "slug": "_user_category_occupation_option_13",
    "title": "Customer Service",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 42,
    "slug": "_user_category_occupation_option_14",
    "title": "Digital Marketing",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 43,
    "slug": "_user_category_occupation_option_15",
    "title": "E-commerce",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 44,
    "slug": "_user_category_occupation_option_16",
    "title": "Education",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 45,
    "slug": "_user_category_occupation_option_17",
    "title": "Engineering - Chemical",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 46,
    "slug": "_user_category_occupation_option_18",
    "title": "Engineering - Civil/Construction/Structural",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 47,
    "slug": "_user_category_occupation_option_19",
    "title": "Engineering - Electrical",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 48,
    "slug": "_user_category_occupation_option_20",
    "title": "Engineering - Electronics/Communication",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 49,
    "slug": "_user_category_occupation_option_21",
    "title": "Engineering - Environmental/Health/Safety",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 50,
    "slug": "_user_category_occupation_option_22",
    "title": "Engineering - Industrial",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 51,
    "slug": "_user_category_occupation_option_23",
    "title": "Engineering - Mechanical/Automotive",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 52,
    "slug": "_user_category_occupation_option_24",
    "title": "Engineering - Oil/Gas",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 53,
    "slug": "_user_category_occupation_option_25",
    "title": "Engineering - Others",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 54,
    "slug": "_user_category_occupation_option_26",
    "title": "Entertainment/Performing Arts",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 55,
    "slug": "_user_category_occupation_option_27",
    "title": "Finance - Audit/Taxation",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 56,
    "slug": "_user_category_occupation_option_28",
    "title": "Finance - Corporate Finance/Investment/Merchant Banking",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 57,
    "slug": "_user_category_occupation_option_29",
    "title": "Finance - General/Cost Accounting",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 58,
    "slug": "_user_category_occupation_option_30",
    "title": "Food Technology/Nutritionist",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 59,
    "slug": "_user_category_occupation_option_31",
    "title": "Food/Beverage/Restaurant Service",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 60,
    "slug": "_user_category_occupation_option_32",
    "title": "General Work (Housekeeper, Driver, Dispatch, Messenger, etc)",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 61,
    "slug": "_user_category_occupation_option_33",
    "title": "Geology/Geophysics",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 62,
    "slug": "_user_category_occupation_option_34",
    "title": "Healthcare - Doctor/Diagnosis",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 63,
    "slug": "_user_category_occupation_option_35",
    "title": "Healthcare - Nurse/Medical Support & Assistant",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 64,
    "slug": "_user_category_occupation_option_36",
    "title": "Healthcare - Pharmacy",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 65,
    "slug": "_user_category_occupation_option_37",
    "title": "Hotel Management/Tourism Services",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 66,
    "slug": "_user_category_occupation_option_38",
    "title": "Human Resources",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 67,
    "slug": "_user_category_occupation_option_39",
    "title": "IT/Computer - Hardware",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 68,
    "slug": "_user_category_occupation_option_40",
    "title": "IT/Computer - Network/System/Database Admin",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 69,
    "slug": "_user_category_occupation_option_41",
    "title": "IT/Computer - Software",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 70,
    "slug": "_user_category_occupation_option_42",
    "title": "Journalist/Editor",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 71,
    "slug": "_user_category_occupation_option_43",
    "title": "Law/Legal Services",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 72,
    "slug": "_user_category_occupation_option_44",
    "title": "Logistics/Supply Chain",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 73,
    "slug": "_user_category_occupation_option_45",
    "title": "Maintenance/Repair (Facilities & Machinery)",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 74,
    "slug": "_user_category_occupation_option_46",
    "title": "Manufacturing/Production Operations",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 75,
    "slug": "_user_category_occupation_option_47",
    "title": "Marketing/Business Development",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 76,
    "slug": "_user_category_occupation_option_48",
    "title": "Merchandising",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 77,
    "slug": "_user_category_occupation_option_49",
    "title": "Personal Care/Beauty/Fitness Service",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 78,
    "slug": "_user_category_occupation_option_50",
    "title": "Process Design & Control/Instrumentation",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 79,
    "slug": "_user_category_occupation_option_51",
    "title": "Property/Real Estate",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 80,
    "slug": "_user_category_occupation_option_52",
    "title": "Public Relations/Communications",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 81,
    "slug": "_user_category_occupation_option_53",
    "title": "Publishing/Printing",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 82,
    "slug": "_user_category_occupation_option_54",
    "title": "Purchasing/Inventory/Material & Warehouse Management",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 83,
    "slug": "_user_category_occupation_option_55",
    "title": "Quality Control/Assurance",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 84,
    "slug": "_user_category_occupation_option_56",
    "title": "Quantity Surveying",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 85,
    "slug": "_user_category_occupation_option_57",
    "title": "Sales - Corporate",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 86,
    "slug": "_user_category_occupation_option_58",
    "title": "Sales - Engineering/Technical/IT",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 87,
    "slug": "_user_category_occupation_option_59",
    "title": "Sales - Financial Services (Insurance, Unit Trust, etc)",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 88,
    "slug": "_user_category_occupation_option_60",
    "title": "Sales - Retail/General",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 89,
    "slug": "_user_category_occupation_option_61",
    "title": "Sales - Telesales/Telemarketing",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 90,
    "slug": "_user_category_occupation_option_62",
    "title": "Science & Technology/Laboratory",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 91,
    "slug": "_user_category_occupation_option_63",
    "title": "Secretarial/Executive & Personal Assistant",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 92,
    "slug": "_user_category_occupation_option_64",
    "title": "Security/Armed Forces/Protective Services",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 93,
    "slug": "_user_category_occupation_option_65",
    "title": "Social & Counselling Service",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 94,
    "slug": "_user_category_occupation_option_66",
    "title": "Student",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 95,
    "slug": "_user_category_occupation_option_67",
    "title": "Technical & Helpdesk Support",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 96,
    "slug": "_user_category_occupation_option_68",
    "title": "Training & Development",
    "content": null,
    "parent_id": 28
  },
  {
    "id": 97,
    "slug": "_user_category_occupation_option_others",
    "title": "Others",
    "content": null,
    "parent_id": 28
  }
]

export const stateOptions = [
  {
    "id": 1,
    "slug": "JHR",
    "title": "Johor",
    "content": null
  },
  {
    "id": 2,
    "slug": "KDH",
    "title": "Kedah",
    "content": null
  },
  {
    "id": 3,
    "slug": "KTN",
    "title": "Kelantan",
    "content": null
  },
  {
    "id": 4,
    "slug": "MLK",
    "title": "Melaka",
    "content": null
  },
  {
    "id": 5,
    "slug": "NSN",
    "title": "Negeri Sembilan",
    "content": null
  },
  {
    "id": 6,
    "slug": "PHG",
    "title": "Pahang",
    "content": null
  },
  {
    "id": 7,
    "slug": "PRK",
    "title": "Perak",
    "content": null
  },
  {
    "id": 8,
    "slug": "PLS",
    "title": "Perlis",
    "content": null
  },
  {
    "id": 9,
    "slug": "PNG",
    "title": "Pulau Pinang",
    "content": null
  },
  {
    "id": 10,
    "slug": "SBH",
    "title": "Sabah",
    "content": null
  },
  {
    "id": 11,
    "slug": "SRW",
    "title": "Sarawak",
    "content": null
  },
  {
    "id": 12,
    "slug": "SGR",
    "title": "Selangor",
    "content": null
  },
  {
    "id": 13,
    "slug": "TRG",
    "title": "Terengganu",
    "content": null
  },
  {
    "id": 14,
    "slug": "KUL",
    "title": "W.P. Kuala Lumpur",
    "content": null
  },
  {
    "id": 15,
    "slug": "LBN",
    "title": "W.P. Labuan",
    "content": null
  },
  {
    "id": 16,
    "slug": "PJY",
    "title": "W.P. Putrajaya",
    "content": null
  }
]

export const localityOptions = [
  {
    "id": 1,
    "slug": "jhr_ayer_baloi",
    "title": "Ayer Baloi",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 2,
    "slug": "jhr_ayer_hitam",
    "title": "Ayer Hitam",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 3,
    "slug": "jhr_ayer_tawar_2",
    "title": "Ayer Tawar 2",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 4,
    "slug": "jhr_ayer_tawar_3",
    "title": "Ayer Tawar 3",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 5,
    "slug": "jhr_ayer_tawar_4",
    "title": "Ayer Tawar 4",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 6,
    "slug": "jhr_ayer_tawar_5",
    "title": "Ayer Tawar 5",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 7,
    "slug": "jhr_bandar_penawar",
    "title": "Bandar Penawar",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 8,
    "slug": "jhr_bandar_tenggara",
    "title": "Bandar Tenggara",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 9,
    "slug": "jhr_batu_anam",
    "title": "Batu Anam",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 10,
    "slug": "jhr_batu_pahat",
    "title": "Batu Pahat",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 11,
    "slug": "jhr_bekok",
    "title": "Bekok",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 12,
    "slug": "jhr_benut",
    "title": "Benut",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 13,
    "slug": "jhr_bukit_gambir",
    "title": "Bukit Gambir",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 14,
    "slug": "jhr_bukit_pasir",
    "title": "Bukit Pasir",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 15,
    "slug": "jhr_chaah",
    "title": "Chaah",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 16,
    "slug": "jhr_endau",
    "title": "Endau",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 17,
    "slug": "jhr_gelang_patah",
    "title": "Gelang Patah",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 18,
    "slug": "jhr_gerisek",
    "title": "Gerisek",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 19,
    "slug": "jhr_gugusan_taib_andak",
    "title": "Gugusan Taib Andak",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 20,
    "slug": "jhr_jementah",
    "title": "Jementah",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 21,
    "slug": "jhr_johor_bahru",
    "title": "Johor Bahru",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 22,
    "slug": "jhr_kahang",
    "title": "Kahang",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 23,
    "slug": "jhr_kluang",
    "title": "Kluang",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 24,
    "slug": "jhr_kota_tinggi",
    "title": "Kota Tinggi",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 25,
    "slug": "jhr_kukup",
    "title": "Kukup",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 26,
    "slug": "jhr_kulai",
    "title": "Kulai",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 27,
    "slug": "jhr_labis",
    "title": "Labis",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 28,
    "slug": "jhr_layang_layang",
    "title": "Layang-Layang",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 29,
    "slug": "jhr_masai",
    "title": "Masai",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 30,
    "slug": "jhr_mersing",
    "title": "Mersing",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 31,
    "slug": "jhr_muar",
    "title": "Muar",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 32,
    "slug": "jhr_nusajaya",
    "title": "Nusajaya",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 33,
    "slug": "jhr_pagoh",
    "title": "Pagoh",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 34,
    "slug": "jhr_paloh",
    "title": "Paloh",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 35,
    "slug": "jhr_panchor",
    "title": "Panchor",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 36,
    "slug": "jhr_parit_jawa",
    "title": "Parit Jawa",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 37,
    "slug": "jhr_parit_raja",
    "title": "Parit Raja",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 38,
    "slug": "jhr_parit_sulong",
    "title": "Parit Sulong",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 39,
    "slug": "jhr_pasir_gudang",
    "title": "Pasir Gudang",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 40,
    "slug": "jhr_pekan_nenas",
    "title": "Pekan Nenas",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 41,
    "slug": "jhr_pengerang",
    "title": "Pengerang",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 42,
    "slug": "jhr_pontian",
    "title": "Pontian",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 43,
    "slug": "jhr_rengam",
    "title": "Rengam",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 44,
    "slug": "jhr_rengit",
    "title": "Rengit",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 45,
    "slug": "jhr_segamat",
    "title": "Segamat",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 46,
    "slug": "jhr_semerah",
    "title": "Semerah",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 47,
    "slug": "jhr_senai",
    "title": "Senai",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 48,
    "slug": "jhr_senggarang",
    "title": "Senggarang",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 49,
    "slug": "jhr_seri_gading",
    "title": "Seri Gading",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 50,
    "slug": "jhr_seri_medan",
    "title": "Seri Medan",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 51,
    "slug": "jhr_simpang_rengam",
    "title": "Simpang Rengam",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 52,
    "slug": "jhr_sungai_mati",
    "title": "Sungai Mati",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 53,
    "slug": "jhr_tangkak",
    "title": "Tangkak",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 54,
    "slug": "jhr_ulu_tiram",
    "title": "Ulu Tiram",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 55,
    "slug": "jhr_yong_peng",
    "title": "Yong Peng",
    "content": null,
    "state": {
      "id": 1,
      "slug": "JHR",
      "title": "Johor",
      "content": null
    }
  },
  {
    "id": 56,
    "slug": "kdh_alor_setar",
    "title": "Alor Setar",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 57,
    "slug": "kdh_ayer_hitam",
    "title": "Ayer Hitam",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 58,
    "slug": "kdh_baling",
    "title": "Baling",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 59,
    "slug": "kdh_bedong",
    "title": "Bedong",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 60,
    "slug": "kdh_bukit_kayu_hitam",
    "title": "Bukit Kayu Hitam",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 61,
    "slug": "kdh_changloon",
    "title": "Changloon",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 62,
    "slug": "kdh_gurun",
    "title": "Gurun",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 63,
    "slug": "kdh_jeniang",
    "title": "Jeniang",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 64,
    "slug": "kdh_jitra",
    "title": "Jitra",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 65,
    "slug": "kdh_karangan",
    "title": "Karangan",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 66,
    "slug": "kdh_kepala_batas",
    "title": "Kepala Batas",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 67,
    "slug": "kdh_kodiang",
    "title": "Kodiang",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 68,
    "slug": "kdh_kota_kuala_muda",
    "title": "Kota Kuala Muda",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 69,
    "slug": "kdh_kota_sarang_semut",
    "title": "Kota Sarang Semut",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 70,
    "slug": "kdh_kuala_kedah",
    "title": "Kuala Kedah",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 71,
    "slug": "kdh_kuala_ketil",
    "title": "Kuala Ketil",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 72,
    "slug": "kdh_kuala_nerang",
    "title": "Kuala Nerang",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 73,
    "slug": "kdh_kuala_pegang",
    "title": "Kuala Pegang",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 74,
    "slug": "kdh_kulim",
    "title": "Kulim",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 75,
    "slug": "kdh_kupang",
    "title": "Kupang",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 76,
    "slug": "kdh_langgar",
    "title": "Langgar",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 77,
    "slug": "kdh_langkawi",
    "title": "Langkawi",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 78,
    "slug": "kdh_lunas",
    "title": "Lunas",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 79,
    "slug": "kdh_merbok",
    "title": "Merbok",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 80,
    "slug": "kdh_padang_serai",
    "title": "Padang Serai",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 81,
    "slug": "kdh_pendang",
    "title": "Pendang",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 82,
    "slug": "kdh_pokok_sena",
    "title": "Pokok Sena",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 83,
    "slug": "kdh_serdang",
    "title": "Serdang",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 84,
    "slug": "kdh_sik",
    "title": "Sik",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 85,
    "slug": "kdh_simpang_empat",
    "title": "Simpang Empat",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 86,
    "slug": "kdh_sungai_petani",
    "title": "Sungai Petani",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 87,
    "slug": "kdh_universiti_utara_malaysia",
    "title": "Universiti Utara Malaysia",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 88,
    "slug": "kdh_yan",
    "title": "Yan",
    "content": null,
    "state": {
      "id": 2,
      "slug": "KDH",
      "title": "Kedah",
      "content": null
    }
  },
  {
    "id": 89,
    "slug": "ktn_ayer_lanas",
    "title": "Ayer Lanas",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 90,
    "slug": "ktn_bachok",
    "title": "Bachok",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 91,
    "slug": "ktn_cherang_ruku",
    "title": "Cherang Ruku",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 92,
    "slug": "ktn_dabong",
    "title": "Dabong",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 93,
    "slug": "ktn_gua_musang",
    "title": "Gua Musang",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 94,
    "slug": "ktn_jeli",
    "title": "Jeli",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 95,
    "slug": "ktn_kem_desa_pahlawan",
    "title": "Kem Desa Pahlawan",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 96,
    "slug": "ktn_ketereh",
    "title": "Ketereh",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 97,
    "slug": "ktn_kota_bharu",
    "title": "Kota Bharu",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 98,
    "slug": "ktn_kuala_balah",
    "title": "Kuala Balah",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 99,
    "slug": "ktn_kuala_krai",
    "title": "Kuala Krai",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 100,
    "slug": "ktn_machang",
    "title": "Machang",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 101,
    "slug": "ktn_melor",
    "title": "Melor",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 102,
    "slug": "ktn_pasir_mas",
    "title": "Pasir Mas",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 103,
    "slug": "ktn_pasir_puteh",
    "title": "Pasir Puteh",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 104,
    "slug": "ktn_pulai_chondong",
    "title": "Pulai Chondong",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 105,
    "slug": "ktn_rantau_panjang",
    "title": "Rantau Panjang",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 106,
    "slug": "ktn_selising",
    "title": "Selising",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 107,
    "slug": "ktn_tanah_merah",
    "title": "Tanah Merah",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 108,
    "slug": "ktn_temangan",
    "title": "Temangan",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 109,
    "slug": "ktn_tumpat",
    "title": "Tumpat",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 110,
    "slug": "ktn_wakaf_bharu",
    "title": "Wakaf Bharu",
    "content": null,
    "state": {
      "id": 3,
      "slug": "KTN",
      "title": "Kelantan",
      "content": null
    }
  },
  {
    "id": 111,
    "slug": "kul_kuala_lumpur",
    "title": "Kuala Lumpur",
    "content": null,
    "state": {
      "id": 14,
      "slug": "KUL",
      "title": "W.P. Kuala Lumpur",
      "content": null
    }
  },
  {
    "id": 112,
    "slug": "kul_pandan",
    "title": "Pandan",
    "content": null,
    "state": {
      "id": 14,
      "slug": "KUL",
      "title": "W.P. Kuala Lumpur",
      "content": null
    }
  },
  {
    "id": 113,
    "slug": "kul_setapak",
    "title": "Setapak",
    "content": null,
    "state": {
      "id": 14,
      "slug": "KUL",
      "title": "W.P. Kuala Lumpur",
      "content": null
    }
  },
  {
    "id": 114,
    "slug": "sgr_ampang",
    "title": "Ampang",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 115,
    "slug": "sgr_bandar_baru_bangi",
    "title": "Bandar Baru Bangi",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 116,
    "slug": "sgr_bandar_puncak_alam",
    "title": "Bandar Puncak Alam",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 117,
    "slug": "sgr_bangi",
    "title": "Bangi",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 118,
    "slug": "sgr_banting",
    "title": "Banting",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 119,
    "slug": "sgr_batang_berjuntai",
    "title": "Batang Berjuntai",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 120,
    "slug": "sgr_batang_kali",
    "title": "Batang Kali",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 121,
    "slug": "sgr_batu_arang",
    "title": "Batu Arang",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 122,
    "slug": "sgr_batu_caves",
    "title": "Batu Caves",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 123,
    "slug": "sgr_beranang",
    "title": "Beranang",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 124,
    "slug": "sgr_bukit_rotan",
    "title": "Bukit Rotan",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 125,
    "slug": "sgr_cheras",
    "title": "Cheras",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 126,
    "slug": "sgr_cyberjaya",
    "title": "Cyberjaya",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 127,
    "slug": "sgr_dengkil",
    "title": "Dengkil",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 128,
    "slug": "sgr_gombak",
    "title": "Gombak",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 129,
    "slug": "sgr_hulu_langat",
    "title": "Hulu Langat",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 130,
    "slug": "sgr_jenjarom",
    "title": "Jenjarom",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 131,
    "slug": "sgr_jeram",
    "title": "Jeram",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 132,
    "slug": "sgr_kajang",
    "title": "Kajang",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 133,
    "slug": "sgr_kapar",
    "title": "Kapar",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 134,
    "slug": "sgr_kerling",
    "title": "Kerling",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 135,
    "slug": "sgr_klang",
    "title": "Klang",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 136,
    "slug": "sgr_klia",
    "title": "KLIA",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 137,
    "slug": "sgr_kuala_kubu_baru",
    "title": "Kuala Kubu Baru",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 138,
    "slug": "sgr_kuala_selangor",
    "title": "Kuala Selangor",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 139,
    "slug": "sgr_pelabuhan_klang",
    "title": "Pelabuhan Klang",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 140,
    "slug": "sgr_petaling_jaya",
    "title": "Petaling Jaya",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 141,
    "slug": "sgr_puchong",
    "title": "Puchong",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 142,
    "slug": "sgr_pulau_carey",
    "title": "Pulau Carey",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 143,
    "slug": "sgr_pulau_indah",
    "title": "Pulau Indah",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 144,
    "slug": "sgr_pulau_ketam",
    "title": "Pulau Ketam",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 145,
    "slug": "sgr_rasa",
    "title": "Rasa",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 146,
    "slug": "sgr_rawang",
    "title": "Rawang",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 147,
    "slug": "sgr_sabak_bernam",
    "title": "Sabak Bernam",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 148,
    "slug": "sgr_sekinchan",
    "title": "Sekinchan",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 149,
    "slug": "sgr_semenyih",
    "title": "Semenyih",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 150,
    "slug": "sgr_sepang",
    "title": "Sepang",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 151,
    "slug": "sgr_serdang",
    "title": "Serdang",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 152,
    "slug": "sgr_serendah",
    "title": "Serendah",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 153,
    "slug": "sgr_seri_kembangan",
    "title": "Seri Kembangan",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 154,
    "slug": "sgr_shah_alam",
    "title": "Shah Alam",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 155,
    "slug": "sgr_subang_airport",
    "title": "Subang Airport",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 156,
    "slug": "sgr_subang_jaya",
    "title": "Subang Jaya",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 157,
    "slug": "sgr_sungai_ayer_tawar",
    "title": "Sungai Ayer Tawar",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 158,
    "slug": "sgr_sungai_besar",
    "title": "Sungai Besar",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 159,
    "slug": "sgr_sungai_buloh",
    "title": "Sungai Buloh",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 160,
    "slug": "sgr_sungai_pelek",
    "title": "Sungai Pelek",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 161,
    "slug": "sgr_tanjong_karang",
    "title": "Tanjong Karang",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 162,
    "slug": "sgr_tanjong_sepat",
    "title": "Tanjong Sepat",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 163,
    "slug": "sgr_telok_panglima_garang",
    "title": "Telok Panglima Garang",
    "content": null,
    "state": {
      "id": 12,
      "slug": "SGR",
      "title": "Selangor",
      "content": null
    }
  },
  {
    "id": 164,
    "slug": "lbn_labuan",
    "title": "Labuan",
    "content": null,
    "state": {
      "id": 15,
      "slug": "LBN",
      "title": "W.P. Labuan",
      "content": null
    }
  },
  {
    "id": 165,
    "slug": "mlk_alor_gajah",
    "title": "Alor Gajah",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 166,
    "slug": "mlk_asahan",
    "title": "Asahan",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 167,
    "slug": "mlk_ayer_keroh",
    "title": "Ayer Keroh",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 168,
    "slug": "mlk_bemban",
    "title": "Bemban",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 169,
    "slug": "mlk_durian_tunggal",
    "title": "Durian Tunggal",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 170,
    "slug": "mlk_jasin",
    "title": "Jasin",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 171,
    "slug": "mlk_kem_trendak",
    "title": "Kem Trendak",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 172,
    "slug": "mlk_kuala_sungai_baru",
    "title": "Kuala Sungai Baru",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 173,
    "slug": "mlk_lubok_china",
    "title": "Lubok China",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 174,
    "slug": "mlk_masjid_tanah",
    "title": "Masjid Tanah",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 175,
    "slug": "mlk_melaka",
    "title": "Melaka",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 176,
    "slug": "mlk_merlimau",
    "title": "Merlimau",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 177,
    "slug": "mlk_selandar",
    "title": "Selandar",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 178,
    "slug": "mlk_sungai_rambai",
    "title": "Sungai Rambai",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 179,
    "slug": "mlk_sungai_udang",
    "title": "Sungai Udang",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 180,
    "slug": "mlk_tanjong_kling",
    "title": "Tanjong Kling",
    "content": null,
    "state": {
      "id": 4,
      "slug": "MLK",
      "title": "Melaka",
      "content": null
    }
  },
  {
    "id": 181,
    "slug": "nsn_bahau",
    "title": "Bahau",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 182,
    "slug": "nsn_bandar_baru_enstek",
    "title": "Bandar Baru Enstek",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 183,
    "slug": "nsn_bandar_seri_jempol",
    "title": "Bandar Seri Jempol",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 184,
    "slug": "nsn_batu_kikir",
    "title": "Batu Kikir",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 185,
    "slug": "nsn_gemas",
    "title": "Gemas",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 186,
    "slug": "nsn_gemencheh",
    "title": "Gemencheh",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 187,
    "slug": "nsn_johol",
    "title": "Johol",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 188,
    "slug": "nsn_kota",
    "title": "Kota",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 189,
    "slug": "nsn_kuala_klawang",
    "title": "Kuala Klawang",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 190,
    "slug": "nsn_kuala_pilah",
    "title": "Kuala Pilah",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 191,
    "slug": "nsn_labu",
    "title": "Labu",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 192,
    "slug": "nsn_linggi",
    "title": "Linggi",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 193,
    "slug": "nsn_mantin",
    "title": "Mantin",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 194,
    "slug": "nsn_nilai",
    "title": "Nilai",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 195,
    "slug": "nsn_port_dickson",
    "title": "Port Dickson",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 196,
    "slug": "nsn_pusat_bandar_palong",
    "title": "Pusat Bandar Palong",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 197,
    "slug": "nsn_rantau",
    "title": "Rantau",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 198,
    "slug": "nsn_rembau",
    "title": "Rembau",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 199,
    "slug": "nsn_rompin",
    "title": "Rompin",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 200,
    "slug": "nsn_seremban",
    "title": "Seremban",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 201,
    "slug": "nsn_si_rusa",
    "title": "Si Rusa",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 202,
    "slug": "nsn_simpang_durian",
    "title": "Simpang Durian",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 203,
    "slug": "nsn_simpang_pertang",
    "title": "Simpang Pertang",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 204,
    "slug": "nsn_tampin",
    "title": "Tampin",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 205,
    "slug": "nsn_tanjong_ipoh",
    "title": "Tanjong Ipoh",
    "content": null,
    "state": {
      "id": 5,
      "slug": "NSN",
      "title": "Negeri Sembilan",
      "content": null
    }
  },
  {
    "id": 206,
    "slug": "phg_balok",
    "title": "Balok",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 207,
    "slug": "phg_bandar_bera",
    "title": "Bandar Bera",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 208,
    "slug": "phg_bandar_pusat_jengka",
    "title": "Bandar Pusat Jengka",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 209,
    "slug": "phg_bandar_tun_abdul_razak",
    "title": "Bandar Tun Abdul Razak",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 210,
    "slug": "phg_benta",
    "title": "Benta",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 211,
    "slug": "phg_bentong",
    "title": "Bentong",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 212,
    "slug": "phg_brinchang",
    "title": "Brinchang",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 213,
    "slug": "phg_bukit_fraser",
    "title": "Bukit Fraser",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 214,
    "slug": "phg_bukit_goh",
    "title": "Bukit Goh",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 215,
    "slug": "phg_chenor",
    "title": "Chenor",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 216,
    "slug": "phg_chini",
    "title": "Chini",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 217,
    "slug": "phg_damak",
    "title": "Damak",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 218,
    "slug": "phg_dong",
    "title": "Dong",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 219,
    "slug": "phg_gambang",
    "title": "Gambang",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 220,
    "slug": "phg_genting_highlands",
    "title": "Genting Highlands",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 221,
    "slug": "phg_jaya_gading",
    "title": "Jaya Gading",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 222,
    "slug": "phg_jerantut",
    "title": "Jerantut",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 223,
    "slug": "phg_karak",
    "title": "Karak",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 224,
    "slug": "phg_kemayan",
    "title": "Kemayan",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 225,
    "slug": "phg_kuala_krau",
    "title": "Kuala Krau",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 226,
    "slug": "phg_kuala_lipis",
    "title": "Kuala Lipis",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 227,
    "slug": "phg_kuala_rompin",
    "title": "Kuala Rompin",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 228,
    "slug": "phg_kuantan",
    "title": "Kuantan",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 229,
    "slug": "phg_lanchang",
    "title": "Lanchang",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 230,
    "slug": "phg_lurah_bilut",
    "title": "Lurah Bilut",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 231,
    "slug": "phg_maran",
    "title": "Maran",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 232,
    "slug": "phg_mentakab",
    "title": "Mentakab",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 233,
    "slug": "phg_muadzam_shah",
    "title": "Muadzam Shah",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 234,
    "slug": "phg_padang_tengku",
    "title": "Padang Tengku",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 235,
    "slug": "phg_pekan",
    "title": "Pekan",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 236,
    "slug": "phg_raub",
    "title": "Raub",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 237,
    "slug": "phg_ringlet",
    "title": "Ringlet",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 238,
    "slug": "phg_sega",
    "title": "Sega",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 239,
    "slug": "phg_sungai_koyan",
    "title": "Sungai Koyan",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 240,
    "slug": "phg_sungai_lembing",
    "title": "Sungai Lembing",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 241,
    "slug": "phg_sungai_ruan",
    "title": "Sungai Ruan",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 242,
    "slug": "phg_tanah_rata",
    "title": "Tanah Rata",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 243,
    "slug": "phg_temerloh",
    "title": "Temerloh",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 244,
    "slug": "phg_triang",
    "title": "Triang",
    "content": null,
    "state": {
      "id": 6,
      "slug": "PHG",
      "title": "Pahang",
      "content": null
    }
  },
  {
    "id": 245,
    "slug": "png_ayer_itam",
    "title": "Ayer Itam",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 246,
    "slug": "png_balik_pulau",
    "title": "Balik Pulau",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 247,
    "slug": "png_bandar_bahru",
    "title": "Bandar Bahru",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 248,
    "slug": "png_batu_ferringhi",
    "title": "Batu Ferringhi",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 249,
    "slug": "png_batu_maung",
    "title": "Batu Maung",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 250,
    "slug": "png_bayan_lepas",
    "title": "Bayan Lepas",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 251,
    "slug": "png_bukit_mertajam",
    "title": "Bukit Mertajam",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 252,
    "slug": "png_butterworth",
    "title": "Butterworth",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 253,
    "slug": "png_gelugor",
    "title": "Gelugor",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 254,
    "slug": "png_jelutong",
    "title": "Jelutong",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 255,
    "slug": "png_kepala_batas",
    "title": "Kepala Batas",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 256,
    "slug": "png_kubang_semang",
    "title": "Kubang Semang",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 257,
    "slug": "png_nibong_tebal",
    "title": "Nibong Tebal",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 258,
    "slug": "png_padang_tembak",
    "title": "Padang Tembak",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 259,
    "slug": "png_penaga",
    "title": "Penaga",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 260,
    "slug": "png_penang_hill",
    "title": "Penang Hill",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 261,
    "slug": "png_perai",
    "title": "Perai",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 262,
    "slug": "png_permatang_pauh",
    "title": "Permatang Pauh",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 263,
    "slug": "png_pulau_pinang",
    "title": "Pulau Pinang",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 264,
    "slug": "png_simpang_ampat",
    "title": "Simpang Ampat",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 265,
    "slug": "png_sungai_jawi",
    "title": "Sungai Jawi",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 266,
    "slug": "png_tanjong_bungah",
    "title": "Tanjong Bungah",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 267,
    "slug": "png_tasek_gelugor",
    "title": "Tasek Gelugor",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 268,
    "slug": "png_usm_pulau_pinang",
    "title": "USM Pulau Pinang",
    "content": null,
    "state": {
      "id": 9,
      "slug": "PNG",
      "title": "Pulau Pinang",
      "content": null
    }
  },
  {
    "id": 269,
    "slug": "prk_ayer_tawar",
    "title": "Ayer Tawar",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 270,
    "slug": "prk_bagan_datoh",
    "title": "Bagan Datoh",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 271,
    "slug": "prk_bagan_serai",
    "title": "Bagan Serai",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 272,
    "slug": "prk_bandar_baharu",
    "title": "Bandar Baharu",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 273,
    "slug": "prk_bandar_seri_iskandar",
    "title": "Bandar Seri Iskandar",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 274,
    "slug": "prk_batu_gajah",
    "title": "Batu Gajah",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 275,
    "slug": "prk_batu_kurau",
    "title": "Batu Kurau",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 276,
    "slug": "prk_behrang_stesen",
    "title": "Behrang Stesen",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 277,
    "slug": "prk_bidor",
    "title": "Bidor",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 278,
    "slug": "prk_bota",
    "title": "Bota",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 279,
    "slug": "prk_bruas",
    "title": "Bruas",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 280,
    "slug": "prk_changkat_jering",
    "title": "Changkat Jering",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 281,
    "slug": "prk_changkat_keruing",
    "title": "Changkat Keruing",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 282,
    "slug": "prk_chemor",
    "title": "Chemor",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 283,
    "slug": "prk_chenderiang",
    "title": "Chenderiang",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 284,
    "slug": "prk_chenderong_balai",
    "title": "Chenderong Balai",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 285,
    "slug": "prk_chikus",
    "title": "Chikus",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 286,
    "slug": "prk_enggor",
    "title": "Enggor",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 287,
    "slug": "prk_gerik",
    "title": "Gerik",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 288,
    "slug": "prk_gopeng",
    "title": "Gopeng",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 289,
    "slug": "prk_hutan_melintang",
    "title": "Hutan Melintang",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 290,
    "slug": "prk_intan",
    "title": "Intan",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 291,
    "slug": "prk_ipoh",
    "title": "Ipoh",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 292,
    "slug": "prk_jeram",
    "title": "Jeram",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 293,
    "slug": "prk_kampar",
    "title": "Kampar",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 294,
    "slug": "prk_kampung_gajah",
    "title": "Kampung Gajah",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 295,
    "slug": "prk_kampung_kepayang",
    "title": "Kampung Kepayang",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 296,
    "slug": "prk_kamunting",
    "title": "Kamunting",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 297,
    "slug": "prk_kuala_kangsar",
    "title": "Kuala Kangsar",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 298,
    "slug": "prk_kuala_kurau",
    "title": "Kuala Kurau",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 299,
    "slug": "prk_kuala_sepetang",
    "title": "Kuala Sepetang",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 300,
    "slug": "prk_lambor_kanan",
    "title": "Lambor Kanan",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 301,
    "slug": "prk_langkap",
    "title": "Langkap",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 302,
    "slug": "prk_lenggong",
    "title": "Lenggong",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 303,
    "slug": "prk_lumut",
    "title": "Lumut",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 304,
    "slug": "prk_malim_nawar",
    "title": "Malim Nawar",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 305,
    "slug": "prk_mambang_di_awan",
    "title": "Mambang Di Awan",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 306,
    "slug": "prk_manong",
    "title": "Manong",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 307,
    "slug": "prk_matang",
    "title": "Matang",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 308,
    "slug": "prk_padang_rengas",
    "title": "Padang Rengas",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 309,
    "slug": "prk_pangkor",
    "title": "Pangkor",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 310,
    "slug": "prk_pantai_remis",
    "title": "Pantai Remis",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 311,
    "slug": "prk_parit",
    "title": "Parit",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 312,
    "slug": "prk_parit_buntar",
    "title": "Parit Buntar",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 313,
    "slug": "prk_pengkalan_hulu",
    "title": "Pengkalan Hulu",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 314,
    "slug": "prk_pusing",
    "title": "Pusing",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 315,
    "slug": "prk_rantau_panjang",
    "title": "Rantau Panjang",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 316,
    "slug": "prk_sauk",
    "title": "Sauk",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 317,
    "slug": "prk_selama",
    "title": "Selama",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 318,
    "slug": "prk_selekoh",
    "title": "Selekoh",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 319,
    "slug": "prk_seri_manjung",
    "title": "Seri Manjung",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 320,
    "slug": "prk_simpang",
    "title": "Simpang",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 321,
    "slug": "prk_simpang_ampat_semanggol",
    "title": "Simpang Ampat Semanggol",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 322,
    "slug": "prk_sitiawan",
    "title": "Sitiawan",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 323,
    "slug": "prk_slim_river",
    "title": "Slim River",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 324,
    "slug": "prk_sungai_siput",
    "title": "Sungai Siput",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 325,
    "slug": "prk_sungai_sumun",
    "title": "Sungai Sumun",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 326,
    "slug": "prk_sungkai",
    "title": "Sungkai",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 327,
    "slug": "prk_taiping",
    "title": "Taiping",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 328,
    "slug": "prk_tanjong_malim",
    "title": "Tanjong Malim",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 329,
    "slug": "prk_tanjong_piandang",
    "title": "Tanjong Piandang",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 330,
    "slug": "prk_tanjong_rambutan",
    "title": "Tanjong Rambutan",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 331,
    "slug": "prk_tanjong_tualang",
    "title": "Tanjong Tualang",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 332,
    "slug": "prk_tapah",
    "title": "Tapah",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 333,
    "slug": "prk_tapah_road",
    "title": "Tapah Road",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 334,
    "slug": "prk_teluk_intan",
    "title": "Teluk Intan",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 335,
    "slug": "prk_temoh",
    "title": "Temoh",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 336,
    "slug": "prk_tldm_lumut",
    "title": "TLDM Lumut",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 337,
    "slug": "prk_trolak",
    "title": "Trolak",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 338,
    "slug": "prk_trong",
    "title": "Trong",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 339,
    "slug": "prk_tronoh",
    "title": "Tronoh",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 340,
    "slug": "prk_ulu_bernam",
    "title": "Ulu Bernam",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 341,
    "slug": "prk_ulu_kinta",
    "title": "Ulu Kinta",
    "content": null,
    "state": {
      "id": 7,
      "slug": "PRK",
      "title": "Perak",
      "content": null
    }
  },
  {
    "id": 342,
    "slug": "pls_arau",
    "title": "Arau",
    "content": null,
    "state": {
      "id": 8,
      "slug": "PLS",
      "title": "Perlis",
      "content": null
    }
  },
  {
    "id": 343,
    "slug": "pls_kaki_bukit",
    "title": "Kaki Bukit",
    "content": null,
    "state": {
      "id": 8,
      "slug": "PLS",
      "title": "Perlis",
      "content": null
    }
  },
  {
    "id": 344,
    "slug": "pls_kangar",
    "title": "Kangar",
    "content": null,
    "state": {
      "id": 8,
      "slug": "PLS",
      "title": "Perlis",
      "content": null
    }
  },
  {
    "id": 345,
    "slug": "pls_kuala_perlis",
    "title": "Kuala Perlis",
    "content": null,
    "state": {
      "id": 8,
      "slug": "PLS",
      "title": "Perlis",
      "content": null
    }
  },
  {
    "id": 346,
    "slug": "pls_padang_besar",
    "title": "Padang Besar",
    "content": null,
    "state": {
      "id": 8,
      "slug": "PLS",
      "title": "Perlis",
      "content": null
    }
  },
  {
    "id": 347,
    "slug": "pls_simpang_ampat",
    "title": "Simpang Ampat",
    "content": null,
    "state": {
      "id": 8,
      "slug": "PLS",
      "title": "Perlis",
      "content": null
    }
  },
  {
    "id": 348,
    "slug": "pjy_putrajaya",
    "title": "Putrajaya",
    "content": null,
    "state": {
      "id": 16,
      "slug": "PJY",
      "title": "W.P. Putrajaya",
      "content": null
    }
  },
  {
    "id": 349,
    "slug": "sbh_beaufort",
    "title": "Beaufort",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 350,
    "slug": "sbh_beluran",
    "title": "Beluran",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 351,
    "slug": "sbh_beverly",
    "title": "Beverly",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 352,
    "slug": "sbh_bongawan",
    "title": "Bongawan",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 353,
    "slug": "sbh_inanam",
    "title": "Inanam",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 354,
    "slug": "sbh_keningau",
    "title": "Keningau",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 355,
    "slug": "sbh_kota_belud",
    "title": "Kota Belud",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 356,
    "slug": "sbh_kota_kinabalu",
    "title": "Kota Kinabalu",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 357,
    "slug": "sbh_kota_kinabatangan",
    "title": "Kota Kinabatangan",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 358,
    "slug": "sbh_kota_marudu",
    "title": "Kota Marudu",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 359,
    "slug": "sbh_kuala_penyu",
    "title": "Kuala Penyu",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 360,
    "slug": "sbh_kudat",
    "title": "Kudat",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 361,
    "slug": "sbh_kunak",
    "title": "Kunak",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 362,
    "slug": "sbh_lahad_datu",
    "title": "Lahad Datu",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 363,
    "slug": "sbh_likas",
    "title": "Likas",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 364,
    "slug": "sbh_membakut",
    "title": "Membakut",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 365,
    "slug": "sbh_menumbok",
    "title": "Menumbok",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 366,
    "slug": "sbh_nabawan",
    "title": "Nabawan",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 367,
    "slug": "sbh_pamol",
    "title": "Pamol",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 368,
    "slug": "sbh_papar",
    "title": "Papar",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 369,
    "slug": "sbh_penampang",
    "title": "Penampang",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 370,
    "slug": "sbh_putatan",
    "title": "Putatan",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 371,
    "slug": "sbh_ranau",
    "title": "Ranau",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 372,
    "slug": "sbh_sandakan",
    "title": "Sandakan",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 373,
    "slug": "sbh_semporna",
    "title": "Semporna",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 374,
    "slug": "sbh_sipitang",
    "title": "Sipitang",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 375,
    "slug": "sbh_tambunan",
    "title": "Tambunan",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 376,
    "slug": "sbh_tamparuli",
    "title": "Tamparuli",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 377,
    "slug": "sbh_tanjung_aru",
    "title": "Tanjung Aru",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 378,
    "slug": "sbh_tawau",
    "title": "Tawau",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 379,
    "slug": "sbh_tenghilan",
    "title": "Tenghilan",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 380,
    "slug": "sbh_tenom",
    "title": "Tenom",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 381,
    "slug": "sbh_tuaran",
    "title": "Tuaran",
    "content": null,
    "state": {
      "id": 10,
      "slug": "SBH",
      "title": "Sabah",
      "content": null
    }
  },
  {
    "id": 382,
    "slug": "srw_asajaya",
    "title": "Asajaya",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 383,
    "slug": "srw_balingian",
    "title": "Balingian",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 384,
    "slug": "srw_baram",
    "title": "Baram",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 385,
    "slug": "srw_bau",
    "title": "Bau",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 386,
    "slug": "srw_bekenu",
    "title": "Bekenu",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 387,
    "slug": "srw_belaga",
    "title": "Belaga",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 388,
    "slug": "srw_belawai",
    "title": "Belawai",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 389,
    "slug": "srw_betong",
    "title": "Betong",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 390,
    "slug": "srw_bintangor",
    "title": "Bintangor",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 391,
    "slug": "srw_bintulu",
    "title": "Bintulu",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 392,
    "slug": "srw_dalat",
    "title": "Dalat",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 393,
    "slug": "srw_daro",
    "title": "Daro",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 394,
    "slug": "srw_debak",
    "title": "Debak",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 395,
    "slug": "srw_engkilili",
    "title": "Engkilili",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 396,
    "slug": "srw_julau",
    "title": "Julau",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 397,
    "slug": "srw_kabong",
    "title": "Kabong",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 398,
    "slug": "srw_kanowit",
    "title": "Kanowit",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 399,
    "slug": "srw_kapit",
    "title": "Kapit",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 400,
    "slug": "srw_kota_samarahan",
    "title": "Kota Samarahan",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 401,
    "slug": "srw_kuching",
    "title": "Kuching",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 402,
    "slug": "srw_lawas",
    "title": "Lawas",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 403,
    "slug": "srw_limbang",
    "title": "Limbang",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 404,
    "slug": "srw_lingga",
    "title": "Lingga",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 405,
    "slug": "srw_long_lama",
    "title": "Long Lama",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 406,
    "slug": "srw_lubok_antu",
    "title": "Lubok Antu",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 407,
    "slug": "srw_lundu",
    "title": "Lundu",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 408,
    "slug": "srw_lutong",
    "title": "Lutong",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 409,
    "slug": "srw_matu",
    "title": "Matu",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 410,
    "slug": "srw_miri",
    "title": "Miri",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 411,
    "slug": "srw_mukah",
    "title": "Mukah",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 412,
    "slug": "srw_nanga_medamit",
    "title": "Nanga Medamit",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 413,
    "slug": "srw_niah",
    "title": "Niah",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 414,
    "slug": "srw_pusa",
    "title": "Pusa",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 415,
    "slug": "srw_roban",
    "title": "Roban",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 416,
    "slug": "srw_saratok",
    "title": "Saratok",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 417,
    "slug": "srw_sarikei",
    "title": "Sarikei",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 418,
    "slug": "srw_sebauh",
    "title": "Sebauh",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 419,
    "slug": "srw_sebuyau",
    "title": "Sebuyau",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 420,
    "slug": "srw_serian",
    "title": "Serian",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 421,
    "slug": "srw_sibu",
    "title": "Sibu",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 422,
    "slug": "srw_siburan",
    "title": "Siburan",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 423,
    "slug": "srw_simunjan",
    "title": "Simunjan",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 424,
    "slug": "srw_song",
    "title": "Song",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 425,
    "slug": "srw_spaoh",
    "title": "Spaoh",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 426,
    "slug": "srw_sri_aman",
    "title": "Sri Aman",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 427,
    "slug": "srw_sundar",
    "title": "Sundar",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 428,
    "slug": "srw_tatau",
    "title": "Tatau",
    "content": null,
    "state": {
      "id": 11,
      "slug": "SRW",
      "title": "Sarawak",
      "content": null
    }
  },
  {
    "id": 429,
    "slug": "trg_ajil",
    "title": "Ajil",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 430,
    "slug": "trg_al_muktatfi_billah_shah",
    "title": "Al Muktatfi Billah Shah",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 431,
    "slug": "trg_ayer_puteh",
    "title": "Ayer Puteh",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 432,
    "slug": "trg_bukit_besi",
    "title": "Bukit Besi",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 433,
    "slug": "trg_bukit_payong",
    "title": "Bukit Payong",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 434,
    "slug": "trg_ceneh",
    "title": "Ceneh",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 435,
    "slug": "trg_chalok",
    "title": "Chalok",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 436,
    "slug": "trg_cukai",
    "title": "Cukai",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 437,
    "slug": "trg_dungun",
    "title": "Dungun",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 438,
    "slug": "trg_jerteh",
    "title": "Jerteh",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 439,
    "slug": "trg_kampung_raja",
    "title": "Kampung Raja",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 440,
    "slug": "trg_kemasek",
    "title": "Kemasek",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 441,
    "slug": "trg_kerteh",
    "title": "Kerteh",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 442,
    "slug": "trg_ketengah_jaya",
    "title": "Ketengah Jaya",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 443,
    "slug": "trg_kijal",
    "title": "Kijal",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 444,
    "slug": "trg_kuala_berang",
    "title": "Kuala Berang",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 445,
    "slug": "trg_kuala_besut",
    "title": "Kuala Besut",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 446,
    "slug": "trg_kuala_terengganu",
    "title": "Kuala Terengganu",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 447,
    "slug": "trg_marang",
    "title": "Marang",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 448,
    "slug": "trg_paka",
    "title": "Paka",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 449,
    "slug": "trg_permaisuri",
    "title": "Permaisuri",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  },
  {
    "id": 450,
    "slug": "trg_sungai_tong",
    "title": "Sungai Tong",
    "content": null,
    "state": {
      "id": 13,
      "slug": "TRG",
      "title": "Terengganu",
      "content": null
    }
  }
]

export const genderOptions = [
  { id: 'M', title: 'Male' },
  { id: 'F', title: 'Female' }
]